<template>
	<transition name="fadein">
		<div class="loading">
			<img src="../assets/progress-circle-success.svg"/>
			<h3>{{textos_lang.loading.cargando[lang]}}</h3>
      <h4 v-if="calculant">{{textos_lang.loading.calculando[lang]}}</h4>
      <h4 v-if="errorloading">{{textos_lang.loading.error[lang]}}</h4>
		</div>
	</transition>
</template>

<script>
import Vuex from 'vuex'
export default {

  name: 'Loading',

  data () {
    return {

    }
  }, 
  computed:{
  	...Vuex.mapState(['loading','calculant','errorloading', 'textos_lang','lang']),
  },
}
</script>

<style lang="css" scoped>
</style>