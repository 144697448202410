<template>
  <header class="header-container">
    <div class="header">
      <router-link to="#" class="header__logo">
        <img :src="UrbanzebLogo" alt="urbanZEB logo" class="logo__img" />
      </router-link>
      <!-- HEADER MOBILE VERSION -->
      <div class="header__menu--mobile mobile">
        <div class="mobile__burger">
          <BurgerButton
            :active="isActive"
            :bar-color="isActive ? '#ffffff' : '#4197ae'"
            :bar-height="3"
            :bar-width="32"
            @click="isActive = !isActive"
          />
        </div>
        <div v-bind:class="[isActive ? closeTransitionMenu : '', openTransitionMenu]"></div>

        <nav v-bind:class="[isActive ? menuOpened : '', closeOpened]">
          
            <ul class="menu--mobile__list">
              <li @click="isActive = !isActive" class="list__item--header"><a href="http://urbanzeb.com" class="item__link ">urbanZEB</a></li>
              <template v-if="currentApp.tipo==='open'">
                <li @click="isActive = !isActive" class="list__item--header"><a href="http://demo.urbanzeb.com/" class="item__link">demo</a></li>
                <li @click="isActive = !isActive" class="list__item--header"><a href="/" class="item__link highligthed-bold">open</a></li>
              </template>
              <template v-else-if="currentApp.tipo==='demo'">
                <li @click="isActive = !isActive" class="list__item--header"><a href="/" class="item__link highligthed-bold">demo</a></li>
                <li @click="isActive = !isActive" class="list__item--header"><a href="http://open.urbanzeb.com" class="item__link">open</a></li>
              </template>
              <!-- muestro el lenguaje que me devuelve menu_lang -->
              <!-- <li @click="$i18n.locale = menu_lang.locale" class="list__item--header">{{menu_lang.text}}</li> -->
              <li class="list__item--header" v-if="lang=='ca'">
                <div class="list__item--header" @click="changeLang('es')" >
                  [es]
                </div>
              </li> 
              <li class="list__item--header" v-if="lang=='es'">
                <div class="list__item--header" @click="changeLang('ca')" >
                  [ca]
                </div>
              </li> 
            </ul>
        </nav>
      </div>

      <!-- HEADER DESKTOP VERSION -->
      <div class="header__menu--desktop desktop">        
            <ul class="menu--desktop__list">
              <li class="list__item--header"><a href="http://urbanzeb.com" class="item__link ">urbanZEB</a></li>
              <template v-if="currentApp.tipo==='open'">
                <li class="list__item--header"><a href="http://demo.urbanzeb.com" class="item__link">demo</a></li>
                <li class="list__item--header"><a href="/" class="item__link highligthed-bold">open</a></li>
              </template>
              <template v-else-if="currentApp.tipo==='demo'">
                <li class="list__item--header"><a href="/" class="item__link highligthed-bold">demo</a></li>
                <li class="list__item--header"><a href="http://open.urbanzeb.com" class="item__link">open</a></li>
              </template>
              <!-- muestro el lenguaje que me devuelve menu_lang -->
              <!-- <li @click="$i18n.locale = menu_lang.locale" class="item__link item__lenguage">{{menu_lang.text}}</li> -->
              <li class="item__link item__lenguage" v-if="lang=='ca'">
                <div  class="item__link" @click="changeLang('es')" >
                  [es]
                </div>
              </li> 
              <li class="item__link item__lenguage" v-if="lang=='es'">
                <div  class="item__link" @click="changeLang('ca')" >
                  [ca]
                </div>
              </li> 
            </ul>
      </div>
    </div>
  </header>
</template>

<script>
import Vuex from 'vuex'
import UrbanzebLogo from "../../assets/images/uz_lightblue_white.png";
import BurgerButton from "vue-burger-button";
import {store} from "../../store/store"

export default {
  name: "header",
  components: { BurgerButton },
  data() {
    return {
      isActive: false,
      UrbanzebLogo: UrbanzebLogo,
      openTransitionMenu: "header__screen-opacity",
      closeTransitionMenu: "showOpacityScreen",
      menuOpened: "showMenu",
      closeOpened: "menu__list-dropdown",
      isCatalan: true,
      //creamos un array de objetos con toda la información necesaria por idioma
      lenguages: [
        {text: '[es]', locale: 'es'},
        {text:'[cat]', locale: 'ca'}
        //Inside an object to can add lenguages in future
      ]
    };
  },
  
  computed: {
    ...Vuex.mapState(['lang']),
    menu_lang(){
      //devuelve el lenguaje que no está activo (esto lo podemos hacer así ahora que solo tenemos dos y solo mostramos el que no está activo)
      return this.lenguages.find(lang=>lang.locale!==this.$i18n.locale)
    },
    ...Vuex.mapGetters(['currentApp']),
  },
  methods: {
    changeLang(lang){
      this.$store.state.lang = lang
      this.$router.push(`/${lang}`)
    }
  }
};
</script>

<style lang="scss" src="vue-burger-button/dist/vue-burger-button.css"></style>
<style lang="scss">
@import "./LandingHeader.scss";
</style>
