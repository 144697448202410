<template>
  <div class="customPop">
    <template v-if="unitatVis=='immoble'">
      <p>{{textos_lang.popup.ref[lang]}}</p>
      <template v-if="currentApp.indicadores.sing&&currentApp.indicadores.sing.includes(indicadorSeleccionado)">
        <p><span>{{feature.properties.REFCAT}}</span></p>
      </template>
      <template v-else>
        <p><span>{{feature.properties.ep}}</span></p>
      </template>
    </template>
    <template v-if="unitatVis=='sec_censal'">
      <p>{{textos_lang.popup.sec[lang]}}</p>
      <p><span>{{feature.properties.eq}}</span></p>
    </template>
   
    <p>{{textos_lang.indicadores[indicador_actual.key].name[lang]}}:</p>
    <!--TIENE OPCIONS-->
    <p v-if="indicador_actual.opcions && indicador_actual.opcions[indicador_actual.opcioSeleccionada].subtitle" v-html="textos_lang.indicadores[indicador_actual.key].opciones[indicador_actual.opcioSeleccionada].subtitulo[lang]"></p>
    <!--TIENE OPCIONS Y ES ABSOLUTO-->
    <p v-if="indicador_actual.opcions && feature.properties[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename] && ( indicador_actual.opcions[indicador_actual.opcioSeleccionada].datatipus==='abs'||indicador_actual.datatipus=='abs')">
      <span v-if="indicador_actual.key==29&&indicador_actual.opcioSeleccionada==11" v-html="format(feature.properties[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]) + ' %'"></span>
     <span v-else-if="indicador_actual.key==3" v-html="feature.properties[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename] + ' ' + textos_lang.indicadores[indicador_actual.key].unitat[lang]"></span>
      <span v-else v-html="format(feature.properties[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]) + ' ' + textos_lang.indicadores[indicador_actual.key].unitat[lang]"></span>
    </p>

    <!--TIENE OPCIONS Y ES CATEGÓRICO-->
    <div v-else-if="indicador_actual.opcions && feature.properties[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename] && ( indicador_actual.opcions[indicador_actual.opcioSeleccionada].datatipus==='cat'||indicador_actual.datatipus=='cat')">
      <p v-if="indicador_actual.key==13">
        <span v-html="format(feature.properties[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]) + ' ' + textos_lang.indicadores[indicador_actual.key].unitat[lang]"> </span>
      </p>
      <p v-for="(valor, index) in indicador_actual.valores" v-if="feature.properties[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]===valor.codename||valor.codename.includes(feature.properties[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename])"><span>{{textos_lang.indicadores[indicador_actual.key].valores[index][lang]}}</span></p>
      
    </div>
    <!--TIENE OPCIONS Y ESTÁ VACÍO-->
    <p v-else-if="indicador_actual.opcions && feature.properties[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]==null">
      <span>{{textos_lang.popup.sin[lang]}}</span>
      
    </p>
    <p v-else-if="indicador_actual.opcions">
      <span v-html="format(feature.properties[indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename]) + ' ' + textos_lang.indicadores[indicador_actual.key].unitat[lang]"> </span>
      
    </p>
    <!--NO TIENE OPCIONS Y ES ABSOLUTO-->
    <p v-else-if="!indicador_actual.opcions && feature.properties[indicador_actual.codename] &&  indicador_actual.datatipus=='abs'">
      <span v-if="indicador_actual.key==25||indicador_actual.key==43||indicador_actual.key==26" v-html="format2(feature.properties[indicador_actual.codename])+' '+ textos_lang.indicadores[indicador_actual.key].unitat[lang]"></span>

      <span v-else v-html="format(feature.properties[indicador_actual.codename])+' '+ textos_lang.indicadores[indicador_actual.key].unitat[lang]"></span>
    </p>
    <!--NO TIENE OPCIONS Y ES CATEGÓRICO-->
    <div v-else-if="!indicador_actual.opcions && feature.properties[indicador_actual.codename] &&  indicador_actual.datatipus=='cat'">
      <p v-for="(valor, index) in indicador_actual.valores" v-if="feature.properties[indicador_actual.codename]===valor.codename||valor.codename.includes(feature.properties[indicador_actual.codename])"><span>{{textos_lang.indicadores[indicador_actual.key].valores[index][lang]}}</span></p>
    </div>
    <!--CUALQUIER OTRA COSA (NO TIENE OPCIONS Y ESTÁ VACÍO)-->
    <div v-else-if="!indicador_actual.opcions && feature.properties[indicador_actual.codename] ==null &&  indicador_actual.datatipus=='cat'">{{textos_lang.popup.sin[lang]}} </div>
    <p v-else-if="indicador_actual.opcions">
      <span>{{feature.properties[indicador_actual.codename]}} {{textos_lang.indicadores[indicador_actual.key].unitat[lang]}} </span>
      
    </p>
    <!--BOTÓN "CONSULTAR". La condición es v-if="feature.properties.el!=''" habría que cambiar lo que hay después de != y poner el valor entre comillas simples.-->
    <button v-if="typeof(feature.properties.el) !== 'undefined' && unitatVis=='immoble' && currentApp.tipo!='open'" @click="popupClicked(feature.properties)" class="boton-comp"><span>{{textos_lang.popup.consultar[lang]}} </span><div class="boton"><font-awesome-icon icon="external-link-square-alt" /></div></button>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import { store } from '../store/store'
import numeral from 'numeral'
export default Vue.extend({
  props: {
    feature: {
      required: true,
      type: Object,
    },
  },
  computed : {
    ...Vuex.mapState(['indicadorSeleccionado', 'unitatVis', 'textos_lang','lang']),
    ...Vuex.mapGetters(['indicador_actual','currentApp'])
  },
  methods: {
    popupClicked(feature) {
      window.open(`/referencia&${feature.ej}?l=${this.lang}`, '_blank')
    },
    format(value){
      numeral.locale('es');

      return numeral(value).format();
    },
    format2(value){
      numeral.locale('es');

      return numeral(value).format('0.00');
    }
  },
})
</script>
<style lang="scss">

</style>