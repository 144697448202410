<template>
	<div class="seccio-indicador">
		<div ref="indicador-seleccionat" class="filtres-aplicats">
			<h4 class="subtitle">{{textos_lang.indis.sel[lang]}}</h4>
			<h6 ref="indicadoresel">{{textos_lang.indicadores[indicador_actual.key].name[lang]}}</h6>
		</div>
		<h4 class="subtitle">{{textos_lang.indis.cambio[lang]}}</h4>
		<div ref="menuindicadors" class="menu-indicadors sel-radio">
			<div class="titulos-indicadors" ref="titulos-indicadores">
				<h4 v-if="currentApp.indicadores.arq" @click="showIndicadors($event)" class="titletosel" id="indicadors-arq">{{textos_lang.filtres.arq[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h4>
				<h4 v-if="currentApp.indicadores.ener" @click="showIndicadors($event)" class="titletosel" id="indicadors-ener">{{textos_lang.filtres.ener[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h4>
				<h4 v-if="currentApp.indicadores.eco" @click="showIndicadors($event)" class="titletosel" id="indicadors-eco">{{textos_lang.filtres.eco[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h4>
				<h4 v-if="currentApp.indicadores.gen" @click="showIndicadors($event)" class="titletosel" id="indicadors-gen">{{textos_lang.filtres.gen[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h4>
				<h4 v-if="currentApp.indicadores.sing" @click="showIndicadors($event)" class="titletosel" id="indicadors-sing">{{textos_lang.filtres.singu[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h4>
			</div>
			<div class="container-indicadors">
			 	<div ref="indicadors-arq" class="indicadors indicadors-arq tipusarq" v-if="currentApp.indicadores.arq">
				    <h4 @click="resetLeftbar()" class="titlesel">{{textos_lang.filtres.arq[lang]}} <button class="boton sub-boton"><font-awesome-icon icon="arrow-left" /></button></h4>
					<label  v-for="indicador in indicadores" v-if="currentApp.indicadores.arq.includes(indicador.key)&&indicador.unitatVisualitzacio.includes(unitatVis)" >
						<input type="radio" name="selindicador" :value="indicador.key" v-model="indicadorSeleccionado" @change="updateindicador()">
						<span>{{textos_lang.indicadores[indicador.key].name[lang]}}</span>
						<div class="radio-input"></div>
					</label>
				</div>
				<div ref="indicadors-ener" class="indicadors indicadors-ener tipusener" v-if="currentApp.indicadores.ener">
					<h4 @click="resetLeftbar()" class="titlesel">{{textos_lang.filtres.ener[lang]}} <button class="boton sub-boton"><font-awesome-icon icon="arrow-left" /></button></h4>
					<label  v-for="indicador in indicadores" v-if="currentApp.indicadores.ener.includes(indicador.key)&&indicador.unitatVisualitzacio.includes(unitatVis)&&indicador.escenaris.includes(escenariSeleccionado)">
						<input type="radio" name="selindicador" :value="indicador.key" v-model="indicadorSeleccionado" @change="updateindicador()">
						<span>{{textos_lang.indicadores[indicador.key].name[lang]}}</span>
						<div class="radio-input"></div>
					</label>
				</div>
				<div ref="indicadors-eco" class="indicadors indicadors-eco tipuseco" v-if="currentApp.indicadores.eco">
					<h4 @click="resetLeftbar()" class="titlesel">{{textos_lang.filtres.eco[lang]}} <button class="boton sub-boton" ><font-awesome-icon icon="arrow-left" /></button></h4>
					<label  v-for="indicador in indicadores" v-if="currentApp.indicadores.eco.includes(indicador.key)&&indicador.unitatVisualitzacio.includes(unitatVis)&&indicador.escenaris.includes(escenariSeleccionado)">
						<input type="radio" name="selindicador" :value="indicador.key" v-model="indicadorSeleccionado" @change="updateindicador()">
						<span>{{textos_lang.indicadores[indicador.key].name[lang]}}</span>
						<div class="radio-input"></div>
					</label>
				</div>
				<div ref="indicadors-gen" class="indicadors indicadors-gen tipuseco" v-if="currentApp.indicadores.gen">
					<h4 @click="resetLeftbar()" class="titlesel">{{textos_lang.filtres.gen[lang]}} <button class="boton sub-boton" ><font-awesome-icon icon="arrow-left" /></button></h4>
					<label  v-for="indicador in indicadores" v-if="currentApp.indicadores.gen.includes(indicador.key)&&indicador.unitatVisualitzacio.includes(unitatVis)">
						<input type="radio" name="selindicador" :value="indicador.key" v-model="indicadorSeleccionado" @change="updateindicador()">
						<span>{{textos_lang.indicadores[indicador.key].name[lang]}}</span>
						<div class="radio-input"></div>
					</label>
				</div>
				<div ref="indicadors-sing" class="indicadors indicadors-eco tipuseco" v-if="currentApp.indicadores.sing">
					<h4 @click="resetLeftbar()" class="titlesel">{{textos_lang.filtres.singu[lang]}} <button class="boton sub-boton" ><font-awesome-icon icon="arrow-left" /></button></h4>
					<label  v-for="indicador in indicadores" v-if="currentApp.indicadores.sing.includes(indicador.key)&&indicador.unitatVisualitzacio.includes(unitatVis)">
						<input type="radio" name="selindicador" :value="indicador.key" v-model="indicadorSeleccionado" @change="updateindicador()">
						<span>{{textos_lang.indicadores[indicador.key].name[lang]}}</span>
						<div class="radio-input"></div>
					</label>
				</div>
				<div class="opcionsIndicador" v-if="indicador_actual.opcions&&indicador_actual.tipus.includes(menuIndicadors)" >
					<h4 class="subtitle">{{textos_lang.indis.opciones[lang]}}</h4>
					<div v-show="indicador_actual.tipopcions.indexOf('consum')!==-1">
						<h5 class="titlesel">{{textos_lang.filtres.con[lang]}}</h5>
						<label>
							<input type="radio" name="consum" value="0"  v-model="indicador_actual.indicadorOpcions.consum"  @change="updateOpcio()">
							<span>{{textos_lang.filtres.teor[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" name="consum" value="1" v-model="indicador_actual.indicadorOpcions.consum" @change="updateOpcio()">
							<span>{{textos_lang.filtres.real[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label v-if="indicador_actual.key==29">
							<input type="radio" name="consum" value="2" v-model="indicador_actual.indicadorOpcions.consum" @change="updateOpcio()">
							<span>{{textos_lang.filtres.comp[lang]}}</span>
							<div class="radio-input"></div>
						</label>
					</div>
					<div v-show="indicador_actual.tipopcions.indexOf('valor')!==-1">
						<h5 class="titlesel">{{textos_lang.filtres.val[lang]}}</h5>
						<label>
							<input type="radio" name="valor" value="0"  v-model="indicador_actual.indicadorOpcions.valor"  @change="updateOpcio()">
							<span>{{textos_lang.filtres.cat[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" name="valor" value="1" v-model="indicador_actual.indicadorOpcions.valor" @change="updateOpcio()">
							<span>{{textos_lang.filtres.abs[lang]}}</span>
							<div class="radio-input"></div>
						</label>
					</div>
					<!-- si el indicador tiene hipotesi y si el consumo no es 'comparativa' -->
					<div v-show="indicador_actual.tipopcions.indexOf('hipotesi')!==-1&&indicador_actual.indicadorOpcions.consum!=2">
						<h5 class="titlesel" v-if="indicador_actual.tipopcions.indexOf('consum')!==-1&&indicador_actual.indicadorOpcions.consum==1||indicador_actual.key==34">{{textos_lang.filtres.vec[lang]}}</h5>
						<h5 class="titlesel" v-else>{{textos_lang.filtres.hip[lang]}}</h5>
						<!-- si no se da que el indicador es 34 y la unidad no es immb o si no se da que el indicador es 29, el consumo es real y la unidad no es immb-->
						<label v-if="!(indicador_actual.key==34&&indicador_actual.indicadorOpcions.unitat!=0) && !(indicador_actual.key==29&&indicador_actual.indicadorOpcions.unitat!=0&&indicador_actual.indicadorOpcions.consum==1)&& !(indicador_actual.key==30&&indicador_actual.indicadorOpcions.unitat!=0&&indicador_actual.indicadorOpcions.consum==1)">
							<input type="radio" name="hipotesi" value="0" v-model="indicador_actual.indicadorOpcions.hipotesi"  @change="updateOpcio()">
							<span>{{textos_lang.filtres.ele[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label v-if="!(indicador_actual.key==34&&indicador_actual.indicadorOpcions.unitat!=0)&& !(indicador_actual.key==29&&indicador_actual.indicadorOpcions.unitat!=0&&indicador_actual.indicadorOpcions.consum==1)&& !(indicador_actual.key==30&&indicador_actual.indicadorOpcions.unitat!=0&&indicador_actual.indicadorOpcions.consum==1)">
							<input type="radio" name="hipotesi" value="1" v-model="indicador_actual.indicadorOpcions.hipotesi" @change="updateOpcio()">
							<span>{{textos_lang.filtres.gas[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label v-if="indicador_actual.key==34">
							<template v-if="indicador_actual.indicadorOpcions.unitat!=0">
								<input type="radio" name="hipotesi" value="2" v-model="indicador_actual.indicadorOpcions.hipotesi" @change="updateOpcio()" checked>
								<span>{{textos_lang.filtres.tot[lang]}}</span>
								<div class="radio-input checked"></div>
							</template>
							<template v-else>
								<input type="radio" name="hipotesi" value="2" v-model="indicador_actual.indicadorOpcions.hipotesi" @change="updateOpcio()">
								<span>{{textos_lang.filtres.tot[lang]}}</span>
								<div class="radio-input"></div>
							</template>
							
						</label>
						<label v-if="(indicador_actual.key==29&&indicador_actual.indicadorOpcions.consum==1)||(indicador_actual.key==30&&indicador_actual.indicadorOpcions.consum==1)">
							<template v-if="indicador_actual.indicadorOpcions.unitat!=0">
								<input type="radio" name="hipotesi" value="2" v-model="indicador_actual.indicadorOpcions.hipotesi" @change="updateOpcio()" checked>
								<span>{{textos_lang.filtres.tot[lang]}}</span>
								<div class="radio-input checked"></div>
							</template>
							<template v-else>
								<input type="radio" name="hipotesi" value="2" v-model="indicador_actual.indicadorOpcions.hipotesi" @change="updateOpcio()">
								<span>{{textos_lang.filtres.tot[lang]}}</span>
								<div class="radio-input"></div>
							</template>
							
						</label>
					</div>
					<div v-show="indicador_actual.tipopcions.indexOf('sup')!==-1">
						<h5 class="titlesel">{{textos_lang.filtres.uni[lang]}}</h5>
						<label>
							<input type="radio" name="sup" value="0" v-model="indicador_actual.indicadorOpcions.sup"  @change="updateOpcio()">
							<span>{{textos_lang.filtres.tot[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" name="sup" value="1" v-model="indicador_actual.indicadorOpcions.sup"@change="updateOpcio()">
							<span>{{textos_lang.filtres.sobre[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" name="sup" value="2" v-model="indicador_actual.indicadorOpcions.sup"@change="updateOpcio()">
							<span>{{textos_lang.filtres.sota[lang]}}</span>
							<div class="radio-input"></div>
						</label>
					</div>
					<div v-show="indicador_actual.tipopcions.indexOf('llindar')!==-1">
						<h5 class="titlesel">{{textos_lang.filtres.llin[lang]}}</h5>
						<label>
							<input type="radio" name="llindar" value="0" v-model="indicador_actual.indicadorOpcions.llindar" @change="updateOpcio()">
							<span>{{textos_lang.filtres.conf[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" name="llindar" value="1" v-model="indicador_actual.indicadorOpcions.llindar" @change="updateOpcio()">
							<span>{{textos_lang.filtres.sal[lang]}}</span>
							<div class="radio-input"></div>
						</label>
					</div>
					<div v-show="indicador_actual.tipopcions.indexOf('unitat')!==-1&&indicador_actual.indicadorOpcions.consum!=2">
						<h5 class="titlesel">{{textos_lang.filtres.uni[lang]}}</h5>
						<label>
							<input type="radio" name="unitat" value="0" v-model="indicador_actual.indicadorOpcions.unitat"  @change="updateOpcio()">
							<span>{{textos_lang.filtres.pi[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" name="unitat" value="1"v-model="indicador_actual.indicadorOpcions.unitat" @change="updateOpcio()">
							<span>{{textos_lang.filtres.ph[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" name="unitat" value="2"v-model="indicador_actual.indicadorOpcions.unitat" @change="updateOpcio()">
							<span v-html="textos_lang.filtres.pm[lang]"></span>
							<div class="radio-input"></div>
						</label>
					</div>
				</div>
			</div>	
		</div>
	</div>
</template>

<script>
	import Vuex from 'vuex'
	import { store } from '../store/store'
export default {

  	name: 'Indicadors',
  	
  	computed:{ 
  		...Vuex.mapState(['menuIndicadors','indicadores','unitatVis','textos_lang','lang','escenariSeleccionado']),
  		...Vuex.mapGetters(['indicadorsArq','indicadorsEner', 'indicadorsEco', 'indicador_actual', 'estiloIndicador','currentApp' ]),
		indicadorSeleccionado: {
			get() {
				return store.state.indicadorSeleccionado
			},
			set(value) {
				store.commit('change_indicador', value)
				if(this.currentApp.tipo=='open'){
					this.$parent.$refs.filtres.showFiltres2(value);
					this.$parent.$refs.filtres.borrartodos();
				}
			}
		}
	},
	methods: {
		showIndicadors(event){
			var tots= document.getElementsByClassName('indicadors');
			for(var t=0;t < tots.length; t++){
				tots[t].style.display='none';
			}
			var targetId = event.currentTarget.id;
			this.$refs.menuindicadors.style.transform = 'translateX(-250px)';
			var refs = this.$refs;
			Object.entries(refs).forEach(entry => {
				if(entry[0]==targetId) {
					entry[1].style.display='block';
				}
			});
			store.commit('change_menuIndicadors', targetId.slice(11))
			
		},
		updateindicador() {

			const map = this.$parent.$parent.$refs.mapa.$children[0].map;
			var indicador_actual = store.getters.indicador_actual;
			let newUrl;
			let newBounds;
			//compruebo si tengo que cambiar de source
			if(this.currentApp.mapas){
				store.commit('cambio_source')
			} else {
				if(!indicador_actual.opcions) {
					store.commit('update_tabla', ['ind_vis',indicador_actual.codename])
				} else {
					store.commit('update_tabla', ['ind_vis',indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename])
				}
				
				store.commit('change_estiloIndicador', map)
				map.setPaintProperty('uzeb', 'fill-color', store.state.estiloIndicador)
			}
			
		},
		resetLeftbar() {
			this.$refs.menuindicadors.style.transform = 'translateX(0px)';
		},
		updateOpcio(){
			const map = this.$parent.$parent.$refs.mapa.$children[0].map;
			var indicador_actual = store.getters.indicador_actual;

			store.commit('set_opcio');
			if(!indicador_actual.opcions) {
				store.commit('update_tabla', ['ind_vis',indicador_actual.codename])
			} else {
				store.commit('update_tabla', ['ind_vis',indicador_actual.opcions[indicador_actual.opcioSeleccionada].codename])
			}
			store.commit('change_estiloIndicador', map);
			map.setPaintProperty('uzeb', 'fill-color', store.state.estiloIndicador)
			if(this.currentApp.tipo=='open'){
					this.$parent.$refs.filtres.showFiltres2({'ind':store.state.indicadorSeleccionado, 'opt':this.indicador_actual.opcioSeleccionada});
					this.$parent.$refs.filtres.borrartodos();
			}
		},
		
	}
}
</script>

<style lang="scss">

</style>