<template>
	<div class="estadistiques">
		<EstBar v-for="indicador in indicadores" v-if="indicador.datatipus=='cat'" :chartdata="{labels:labelsCat(indicador.key)}" :options="chartOptions"/>
		<!-- <EstBar :chartdata="chartData" :options="chartOptions"/>
		<EstBar :chartdata="chartData2" :options="chartOptions3"/> -->
	</div>
</template>

<script>
	import EstBar from './EstBar'
	import Vuex from 'vuex'
	export default {
	  components:{EstBar},
	  name: 'Estadistiques',
	  computed:{
      	...Vuex.mapState(['indicadores']),
      	...Vuex.mapGetters(['labelsCat'])
      	
      },
	  data () {
	    return {
	    	chartData: {
		      labels: ['January', 'February'],
		      datasets: [
		        {
		          label: 'Data One',
		          backgroundColor: '#f87979',
		          data: [40, 20]
		        }
		      ]
		    },
		    chartOptions: {
		      responsive: true,
		      maintainAspectRatio: false
		    },
		    chartData2: {
		      labels: ['January', 'February'],
		      datasets: [
		        {
		          label: 'Data One',
		          backgroundColor: '#f87979',
		          data: [40, 20]
		        }
		      ]
		    },
		    chartOptions3: {
		      responsive: true,
		      maintainAspectRatio: false
		    }
	    }
	  }
	}
</script>

<style lang="css" scoped>
</style>