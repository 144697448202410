import Vue from 'vue'
//import Vuex from 'vuex'
import App from './App'
import { store } from './store/store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faGlobeEurope, faProjectDiagram, faCity, faListUl, faFilter, faSignOutAlt, faTimes, faAdjust, faFont, faInfo, faArrowRight, faArrowLeft, faExternalLinkSquareAlt, faCog, faPen, faPlus, faCheck, faCircle ,faMinus, faArrowUp, faArrowDown, faMinusSquare,faPaperPlane, faVectorSquare, faFolder, faSave, faFolderPlus, faFolderOpen, faEye, faClone, faTrashAlt, faFolderMinus, faSearch, faShareAlt, faDatabase, faAsterisk, faQuestion,} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from './router'
import VueMobileDetection from "vue-mobile-detection";
import i18n from './i18n'
Vue.use(VueMobileDetection);
library.add(faBars, faGlobeEurope, faProjectDiagram, faCity, faListUl, faFilter, faSignOutAlt, faTimes, faAdjust, faFont, faInfo, faArrowRight, faArrowLeft, faExternalLinkSquareAlt, faCog, faPen, faPlus, faCheck, faCircle,faMinus, faArrowUp, faArrowDown, faMinusSquare,faPaperPlane, faVectorSquare, faFolder, faSave, faFolderPlus, faFolderOpen, faEye, faClone, faTrashAlt, faFolderMinus, faSearch, faShareAlt, faDatabase, faAsterisk, faQuestion,);
Vue.component("font-awesome-icon", FontAwesomeIcon);
new Vue({
  el: '#app',
  store,
  router,
  i18n,
  components: { App },
  template: "<App/>"
})
