<template>
	<div class="leyenda">
		<!--Indicador actual-->
		<h3 class="titulo-seccio" v-html="textos_lang.indicadores[indicador_actual.key].name[lang]"></h3>
		<!-- Subtitulo (open) -->
		<h5 class="subtitulo" v-if="textos_lang.indicadores[indicador_actual.key].subtitulo" v-html="textos_lang.indicadores[indicador_actual.key].subtitulo[lang]"></h5>
		<!-- Súbtitulo Indicador actual-->
		<h3 class="subtitulo-seccio" v-if="indicador_actual.opcions && textos_lang.indicadores[indicador_actual.key].opciones[indicador_actual.opcioSeleccionada].subtitulo" v-html="textos_lang.indicadores[indicador_actual.key].opciones[indicador_actual.opcioSeleccionada].subtitulo[lang]"></h3>
		<!-- Leyenda -->
		<!-- Si tiene opciones -->
		<ul v-if="indicador_actual.opcions">
			<!-- Indicador categorizado -->
			<li class="leyendcat" v-if="indicador_actual.opcions[indicador_actual.opcioSeleccionada].datatipus=='cat'||indicador_actual.datatipus =='cat'" v-for="(valor,index) in indicador_actual.valores">
				<span class="leyenda-color" :style="{background:valor.fillColor}"></span>
				<span class="leyenda-title" v-html="textos_lang.indicadores[indicador_actual.key].valores[index][lang]"></span>
			</li>
			<!-- Indicador absoluto -->
			<li class="leyendabs" v-if="indicador_actual.opcions[indicador_actual.opcioSeleccionada].datatipus=='abs'||indicador_actual.datatipus=='abs'">
				<div class="leyendStandard">
					<span class="leyenda-color leyenda-rango" :style="'background-image: linear-gradient(' + indicador_actual.upcolor + ','+ indicador_actual.midcolor + ',' +indicador_actual.downcolor+')'"></span>
					<!-- Si no tiene opciones-->
					<div class="wrap-title" v-if="indicador_actual.min_corr">
						<span class="leyenda-title" v-html="formatear(indicador_actual.max_corr)+ ' ' +textos_lang.indicadores[indicador_actual.key].unitat[lang]+ textos_lang.leyenda.omas[lang]"></span>
						<span class="leyenda-title" v-html="formatear(indicador_actual.min_corr)+' ' +textos_lang.indicadores[indicador_actual.key].unitat[lang]+ textos_lang.leyenda.omenos[lang]"></span>
						
					</div>
					<!-- Si tiene opciones-->
					<div class="wrap-title" v-else>
						<template v-if="indicador_actual.key==3">
							<span class="leyenda-title" v-html="indicador_actual.opcions[indicador_actual.opcioSeleccionada].max_corr+ ' ' +textos_lang.indicadores[indicador_actual.key].unitat[lang] + textos_lang.leyenda.omas[lang]"></span>
							<span class="leyenda-title" v-html="indicador_actual.opcions[indicador_actual.opcioSeleccionada].min_corr+' ' +textos_lang.indicadores[indicador_actual.key].unitat[lang] + textos_lang.leyenda.omenos[lang]"></span>
						</template>
						<template v-else-if="indicador_actual.key==29&&indicador_actual.opcioSeleccionada==11">
							
							<span class="leyenda-title" v-html="formatear(indicador_actual.opcions[indicador_actual.opcioSeleccionada].max_corr)
							+ ' %'
							+ textos_lang.leyenda.omas[lang]">
							    	
							</span>
							<span class="leyenda-title" v-html="formatear(indicador_actual.opcions[indicador_actual.opcioSeleccionada].min_corr)
							+' %' 
							+ textos_lang.leyenda.omenos[lang]"></span>
						</template>
						<template v-else-if="indicador_actual.unitatTemps">
							
							<span class="leyenda-title" v-html="formatear(indicador_actual.opcions[indicador_actual.opcioSeleccionada].max_corr)
							+ ' '
							+ textos_lang.indicadores[indicador_actual.key].unitat[lang]
							+ '/'
							+ textos_lang.indicadores[indicador_actual.key].opciones[indicador_actual.opcioSeleccionada].uni[lang]
							+ '·'
							+ textos_lang.indicadores[indicador_actual.key].unitatTemps[lang]
							+ textos_lang.leyenda.omas[lang]">
							    	
							</span>
							<span class="leyenda-title" v-html="formatear(indicador_actual.opcions[indicador_actual.opcioSeleccionada].min_corr)
							+' ' 
							+textos_lang.indicadores[indicador_actual.key].unitat[lang]
							+'/'
							+ textos_lang.indicadores[indicador_actual.key].opciones[indicador_actual.opcioSeleccionada].uni[lang]
							+ '·'
							+ textos_lang.indicadores[indicador_actual.key].unitatTemps[lang]
							+ textos_lang.leyenda.omenos[lang]"></span>
						</template>
						<template v-else>
							<span class="leyenda-title" v-html="formatear(indicador_actual.opcions[indicador_actual.opcioSeleccionada].max_corr)+ ' ' +textos_lang.indicadores[indicador_actual.key].unitat[lang] + textos_lang.leyenda.omas[lang]"></span>
							<span class="leyenda-title" v-html="formatear(indicador_actual.opcions[indicador_actual.opcioSeleccionada].min_corr)+' ' +textos_lang.indicadores[indicador_actual.key].unitat[lang] + textos_lang.leyenda.omenos[lang]"></span>
						</template>
					</div>
				</div>
				<div class="leyendEspecial" v-if="indicador_actual.key==4 || indicador_actual.key==5">
					<span class="leyenda-color" :style="{background:indicador_actual.valores[indicador_actual.valores.length-2].fillColor}"></span>
					<span class="leyenda-title" v-if="indicador_actual.key==4">{{textos_lang.leyenda.esp[0][lang]}}</span>
					<span class="leyenda-title" v-if="indicador_actual.key==5">{{textos_lang.leyenda.esp[1][lang]}}</span>
				</div>
				<div class="leyendEspecial" v-if="[39, 40].includes(indicador_actual.key)">
					<span class="leyenda-color" :style="'background: #3FBF6B'"></span>
					<span class="leyenda-title" >{{textos_lang.leyenda.esp[2][lang]}}</span>
					<!-- <span class="leyenda-title" v-if="indicador_actual.key==40">{{textos_lang.leyenda.esp[2][lang]}}</span> -->
				</div>
			</li>
		</ul>
		<!-- Si no tiene opciones -->
		<ul v-else>
			<!-- Indicador categorizado -->
			<li v-if="indicador_actual.datatipus=='cat'" v-for="(valor,index) in indicador_actual.valores" class="leyendcat">
				<span class="leyenda-color" :style="{background:valor.fillColor}"></span>
				<span class="leyenda-title" v-html="textos_lang.indicadores[indicador_actual.key].valores[index][lang]"></span>
			</li>
			<!-- Indicador absoluto -->
			<li v-if="indicador_actual.datatipus=='abs'" class="leyendabs">
				<div class="leyendStandard">
					<span class="leyenda-color leyenda-rango" :style="'background-image: linear-gradient(' + indicador_actual.upcolor + ','+ indicador_actual.midcolor + ',' +indicador_actual.downcolor+')'"></span>
					<div class="wrap-title">
						<span class="leyenda-title" v-html="formatear(indicador_actual.max_corr) + ' ' +textos_lang.indicadores[indicador_actual.key].unitat[lang] + textos_lang.leyenda.omas[lang]"></span>
						<span class="leyenda-title" v-html="formatear(indicador_actual.min_corr)+' '+textos_lang.indicadores[indicador_actual.key].unitat[lang] + textos_lang.leyenda.omenos[lang]"></span>
					</div>
				</div>
			</li>
			<!-- Indicador con extra -->
			<template v-if="indicador_actual.extra">
				<li  v-for="(valor, index) in indicador_actual.extra.valores" class="leyendcat" v-if="valor.img!=''">
					<span class="leyenda-color" :style="'background-image:url('+valor.img+')'" :class="valor.img"></span>
					<span class="leyenda-title">{{textos_lang.indicadores[indicador_actual.key].extra.valores[index][lang]}}</span>
				</li>
			</template>
		</ul>
		<!-- Fuente de los datos (open) -->
		<div class="data" v-if="currentApp.tipo=='open'">
			<p><font-awesome-icon icon="database" />{{textos_lang.leyenda.fuentes[lang]}}{{indicador_actual.fuente}}</p>
			<p><font-awesome-icon icon="asterisk" />{{textos_lang.leyenda.errores[lang]}}</p>

		</div>
	</div>		
			

</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import { store } from '../store/store'
import numeral from 'numeral'

export default {

  	name: 'Leyenda',
  	
  	computed: {
		...Vuex.mapGetters(['indicador_actual','currentApp','currentVista','currentLista', 'currentFiltros']),
		...Vuex.mapState(['filtros','indicadores','lista_filtros', 'rightSidebar','opcioSeleccionada','vista','textos_lang','lang']),
		
	},
	methods: {
		formatear(value){
			numeral.locale('es');
			return numeral(value).format();
		}
	},
	
}
</script>

<style lang="scss" >

</style>