<template>
	<div class="filtres-seccio">
		<!-- Sección filtros aplicados -->
		<div ref="filtres-aplicats" class="filtres-aplicats" v-if="currentApp.tipo!='open'">
			<button v-if="currentLista&&currentLista.length>0" @click="modalseleccion" class="botontexto">{{textos_lang.filtres.guardar[lang]}}<span class="boton"><font-awesome-icon icon="save" /></span></button>
			<h4 class="subtitle">{{textos_lang.filtres.aplicados[lang]}}</h4>
			<p class="nofilter" ref="nofilter" v-if="!currentLista || currentLista.length==0">{{textos_lang.filtres.ningun[lang]}}</p>
			<ul ref="listaaplicats">
				<template v-for="aplicats in indicadores" v-if="!aplicats.opcions&&currentApp.indicadores.tots.includes(aplicats.key)">
				<li v-if="currentLista&&currentLista.includes(aplicats.codename)">
					{{textos_lang.indicadores[aplicats.key].name[lang]}}
					<div class="botonesfiltro">
						<button class="boton sub-boton" :id="aplicats.key" @click="showFiltres2($event)">
							<font-awesome-icon icon="pen" />
						</button>
						<button class="boton sub-boton" @click="borrarfiltro(aplicats.codename, aplicats.name, aplicats.datatipus,aplicats.key)">
							<font-awesome-icon icon="times" />
						</button>
					</div>
					
				</li>
				</template>
				<template v-for="aplicats in indicadores" v-if="aplicats.opcions&&currentApp.indicadores.tots.includes(aplicats.key)">
					<li v-for="(opcio, index) in aplicats.opcions" v-if="currentLista&&currentLista.includes(opcio.codename)">
						<span v-html="textos_lang.indicadores[aplicats.key].name[lang] +'<br>' + textos_lang.indicadores[aplicats.key].opciones[index].subtitulo[lang]"> </span>
						<div class="botonesfiltro">
							<button class="boton sub-boton" :id="opcio.key" @click="showFiltres3(aplicats.key,$event)">
								<font-awesome-icon icon="pen" />
							</button>
							<button class="boton sub-boton" @click="borrarfiltro(opcio.codename, aplicats.name, opcio.datatipus,aplicats.key)">
								<font-awesome-icon icon="times" />
							</button>
						</div>
					</li>
				</template>
				<!-- Area personalizada-->
				<li v-if="currentLista&&currentLista.includes('ej')">
					{{textos_lang.filtres.area[lang]}}
					<div class="botonesfiltro">
						<button class="boton sub-boton" id="ej" @click="showDibuix($event)">
							<font-awesome-icon icon="pen" />
						</button>
						<button class="boton sub-boton" @click="borrarfiltro('ej')">
							<font-awesome-icon icon="times" />
						</button>
					</div>
				</li>
				<!-- Secciones censales-->
				<li v-if="currentLista&&currentLista.includes('er')">
					{{textos_lang.filtres.secciones[lang]}}
					<div class="botonesfiltro">
						<button class="boton sub-boton" id="ej" @click="showSeccions($event)">
							<font-awesome-icon icon="pen" />
						</button>
						<button class="boton sub-boton" @click="borrarfiltro('er')">
							<font-awesome-icon icon="times" />
						</button>
					</div>
				</li>
				<!--Municipis-->
				<li v-if="currentLista&&currentLista.includes('fa')">
					{{textos_lang.filtres.municipios[lang]}}
					<div class="botonesfiltro">
						<button class="boton sub-boton" id="fa" @click="showMunicipis($event)">
							<font-awesome-icon icon="pen" />
						</button>
						<button class="boton sub-boton" @click="borrarfiltro('fa')">
							<font-awesome-icon icon="times" />
						</button>
					</div>
				</li>
			</ul>
		</div>
		<!-- Sección aplicar filtro -->
   		<h4 class="subtitle" v-if="currentApp.tipo!='open'">{{textos_lang.filtres.nuevo[lang]}}</h4>
   		<div ref="menufiltres" class="menu-filtres">
			<!--Títulos categorías filtros Primera columna de menu-filtres-->
			<div class="titulos-filtres" ref="titulos-filtres">
				<template v-if="$isMobile()&&currentApp.tipo!='open'">
					<h4 @click="showFiltres($event)" class="titletosel" id="filtres-geografics" v-if="currentApp.municipis.length>0">{{textos_lang.filtres.geo[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h4>
				</template>
				<template v-if="!$isMobile()&&currentApp.tipo!='open'">
					<h4 @click="showFiltres($event)" class="titletosel" id="filtres-geografics">{{textos_lang.filtres.geo[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h4>
				</template>

				<h4 @click="showFiltres($event)" class="titletosel" id="filtres-arquitectonics" v-if="currentApp.indicadores.arq">{{textos_lang.filtres.arq[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h4>
				<h4 @click="showFiltres($event)" class="titletosel" id="filtres-energetics" v-if="currentApp.indicadores.ener">{{textos_lang.filtres.ener[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h4>
				<h4 @click="showFiltres($event)" class="titletosel" id="filtres-economics" v-if="currentApp.indicadores.eco">{{textos_lang.filtres.eco[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h4>
			</div>
			<!-- Generamos la lista de cada una de las categorías. Esto está en la segunda columna de MENU-FILTRES -->
			<div class="container-filtres">
				<div ref="filtres-geografics" class="filtres filtres-geografics">
				    <h4 @click="resetLeftbar('0px')" class="titlesel">{{textos_lang.filtres.geo[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-left" /></button></h4>
				    <h6 @click="showMunicipis($event)" v-if="currentApp.municipis.length>0">{{textos_lang.filtres.municipios[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right"/></button></h6>
				    <h6 v-if="currentApp.key!=6" @click="showSeccions($event)">{{textos_lang.filtres.secciones[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right"/></button></h6>
				    <h6 @click="showDibuix($event)" id="dibuix" v-if="unitatVis=='immoble'">{{textos_lang.filtres.area[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right"/></button></h6>
				</div>
				<div ref="filtres-arquitectonics" class="filtres filtres-arquitectonics" v-if="currentApp.indicadores.arq">
				    <h4 @click="resetLeftbar('0px')" class="titlesel">{{textos_lang.filtres.arq[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-left" /></button></h4>
					<h6 @click="showFiltres2($event)" :id="indicador.key" v-for="indicador in indicadores" v-if="currentApp.indicadores.arq.includes(indicador.key)">{{textos_lang.indicadores[indicador.key].name[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h6>
				</div>
				<div ref="filtres-energetics" class="filtres filtres-energetics" v-if="currentApp.indicadores.ener">
					<h4 @click="resetLeftbar('0px')" class="titlesel">{{textos_lang.filtres.ener[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-left" /></button></h4>
					<h6 @click="showFiltres2($event)" :id="indicador.key" v-for="indicador in indicadores" v-if="currentApp.indicadores.ener.includes(indicador.key)&& indicador.escenaris.includes(escenariSeleccionado)">{{textos_lang.indicadores[indicador.key].name[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h6>
				</div>
				<div ref="filtres-economics" class="filtres filtres-economics" v-if="currentApp.indicadores.eco">
					<h4 @click="resetLeftbar('0px')" class="titlesel">{{textos_lang.filtres.eco[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-left" /></button></h4>
					<h6 @click="showFiltres2($event)" :id="indicador.key" v-for="indicador in indicadores" v-if="currentApp.indicadores.eco.includes(indicador.key)&& indicador.escenaris.includes(escenariSeleccionado)">{{textos_lang.indicadores[indicador.key].name[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h6>
				</div>
				<div ref="filtres-economics" class="filtres filtres-economics" v-if="currentApp.indicadores.sing">
					<h4 @click="resetLeftbar('0px')" class="titlesel">{{textos_lang.filtres.singu[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-left" /></button></h4>
					<h6 @click="showFiltres2($event)" :id="indicador.key" v-for="indicador in indicadores" v-if="currentApp.indicadores.sing.includes(indicador.key)">{{textos_lang.indicadores[indicador.key].name[lang]}}<button class="boton sub-boton"><font-awesome-icon icon="arrow-right" /></button></h6>
				</div>
			</div>
			<!-- Tercera columna de menú filtres. Vemos el filtro seleccionado o sus opciones (si las tiene) -->
			<div class="container-filtreOpcions">
				<div v-for="indicador in indicadores" v-if="!indicador.opcions && currentApp.indicadores.tots.includes(indicador.key)">
					<div class="lista-filtre sel-check" :ref="indicador.key">
						<h6 @click="resetLeftbar('-250px')" class="titlesel">{{textos_lang.indicadores[indicador.key].name[lang]}}<button class="boton sub-boton" ><font-awesome-icon icon="arrow-left" /></button></h6>
						<label v-if="indicador.datatipus=='cat'" v-for="(valor, index) in indicador.valores">
							<input type="checkbox" :name="indicador.codename" :value="valor.codename" @change="filtrar(indicador.key,indicador.codename,indicador.name,indicador.datatipus)" checked>
							<span v-html="textos_lang.indicadores[indicador.key].valores[index][lang]"></span>
							<div class="checkbox-input"><font-awesome-icon icon="circle" /></div>
						</label>
						
						<hr>
						<label v-if="indicador.datatipus=='cat'">
							<input type="checkbox" class="checkall" :class="indicador.codename" @change="checkall($event, indicador.key,indicador.codename, indicador.name, indicador.datatipus)" checked>
							<span>{{textos_lang.filtres.seltodo[lang]}}</span>
							<div class="checkbox-input"><font-awesome-icon icon="circle"/></div>
						</label>
						<template v-if="indicador.extra">
							<hr class="extra">
							<label v-for="(valor, index) in indicador.extra.valores">
								<input type="checkbox" :name="indicador.extra.codename" @change="filtrar(indicador.extra.key,indicador.extra.codename,indicador.name,indicador.datatipus)" :value="valor.codename" checked>
								<span v-html="textos_lang.indicadores[indicador.key].extra.valores[index][lang]"></span>
								<div class="checkbox-input"><font-awesome-icon icon="circle" /></div>
							</label>
						</template>
						<template v-if="indicador.datatipus=='abs'">
							<div :id="'SL'+indicador.codename"></div>
							<label class="input-filtre" :class="'input'+indicador.codename">{{textos_lang.filtres.max[lang]}} <input type="number" :id="'max' + indicador.codename" class="maxinput"/></label>
							<label class="input-filtre" :class="'input'+indicador.codename">{{textos_lang.filtres.min[lang]}} <input type="number" :id="'min' + indicador.codename" class="mininput"/></label>
							
						</template>
						
					</div>
				</div>
				<div class="lista-filtre dibuix">
					<h6 @click="undoDibuix()" class="titlesel" v-if="!$isMobile()">{{textos_lang.filtres.area[lang]}}<button class="boton sub-boton" ><font-awesome-icon icon="arrow-left" /></button></h6>
					<template v-if="currentZoom>=13">
						<p>{{textos_lang.filtres.dibu[0][lang]}}</p>
						<div class="exp"><span class="mapbox-gl-draw_point"></span>{{textos_lang.filtres.dibu[1][lang]}}</div>
						<div class="exp"><span class="mapbox-gl-draw_polygon"></span> {{textos_lang.filtres.dibu[2][lang]}}</div>
						<div class="exp"><span class="mapbox-gl-draw_trash"></span> {{textos_lang.filtres.dibu[3][lang]}}</div>
					</template>
					<template v-else>
						<p>{{textos_lang.filtres.dibu[4][lang]}}</p>
					</template>
				</div>
				<!-- Filtro municipios-->
				<div class="lista-filtre municipis">
					<h6 @click="resetLeftbar('-250px')" class="titlesel">{{textos_lang.filtres.municipios[lang]}}<button class="boton sub-boton" ><font-awesome-icon icon="arrow-left" /></button></h6>
					<div>
						<label v-for="municipi in currentApp.municipis">
							<input type="checkbox" name="fa" :value="municipi.code" @change="filtrar(null,'fa',municipi.code,'cat')" checked>
							<span v-html="municipi.nom"></span>
							<div class="checkbox-input"><font-awesome-icon icon="circle" /></div>
						</label>
						<hr>
						<label>
							<input type="checkbox" class="checkall" @change="checkall($event,null,'fa', currentApp.municipis, 'cat')" checked>
							<span>{{textos_lang.filtres.seltodo[lang]}}</span>
							<div class="checkbox-input"><font-awesome-icon icon="circle"/></div>
						</label>
					</div>
				</div>
				<!-- Filtro seccions censals -->
				<div class="lista-filtre seccions">
					<h6 @click="undoSeccions()" class="titlesel">{{textos_lang.filtres.secciones[lang]}} <button class="boton sub-boton"><font-awesome-icon icon="arrow-left" /></button></h6>
					<div>{{textos_lang.filtres.selsecciones[lang]}}</div>
				</div>
				<!-- Filtros indicadors -->
				<div v-for="indicador in indicadores" v-if="indicador.opcions  && currentApp.indicadores.tots.includes(indicador.key)&& currentApp.tipo=='open'">
					<div class="lista-filtre sel-check" :ref="'ind'+indicador.key+'opt'+opcion.key" v-for="opcion in indicador.opcions" >
						<h6 @click="resetLeftbar('-250px')" class="titlesel">{{textos_lang.indicadores[indicador.key].name[lang]}}<button class="boton sub-boton" ><font-awesome-icon icon="arrow-left" /></button></h6>
						<div v-if="opcion.datatipus=='cat'||indicador.datatipus=='cat'">
							<label v-for="(valor, index) in indicador.valores">
								<input type="checkbox" :name="opcion.codename" :value="valor.codename" @change="filtrar(indicador.key,opcion.codename,indicador.name,'cat')" checked>
								<span v-html="textos_lang.indicadores[indicador.key].valores[index][lang]"></span>
								<div class="checkbox-input"><font-awesome-icon icon="circle" /></div>
							</label>
							
							<hr>
							<label>
								<input type="checkbox" class="checkall" :class="opcion.codename" @change="checkall($event,indicador.key,opcion.codename, indicador.name, opcion.datatipus)" checked>
								<span>{{textos_lang.filtres.seltodo[lang]}}</span>
								<div class="checkbox-input"><font-awesome-icon icon="circle"/></div>
							</label>
						</div>
						<template v-else-if="opcion.datatipus=='abs'|| indicador.datatipus=='abs'"> 
							<div  :id="'SL'+opcion.codename"></div>
							<label class="input-filtre" :class="'input'+indicador.codename">{{textos_lang.filtres.max[lang]}} <input type="number" :id="'max' + opcion.codename" class="maxinput"/></label>
							<label class="input-filtre" :class="'input'+indicador.codename">{{textos_lang.filtres.min[lang]}} <input type="number" :id="'min' + opcion.codename" class="mininput"/></label>
							
						</template>
					</div>
				</div>
				<div v-for="indicador in indicadores" v-if="indicador.opcions  && currentApp.indicadores.tots.includes(indicador.key) && currentApp.tipo!='open'" :ref='"opcion" + indicador.key' class="lista-opcions">
					
					<div class="lista-filtre sel-check" :ref="'ind'+indicador.key+'opt'+opcion.key" v-for="opcion in indicador.opcions" >
						<h6 @click="resetLeftbar('-250px')" class="titlesel">{{textos_lang.indicadores[indicador.key].name[lang]}}<button class="boton sub-boton" ><font-awesome-icon icon="arrow-left" /></button></h6>
						<!-- <h4 class="subtitle">{{textos_lang.indicadores[indicador.key].opciones[opcion.key].subtitulo[lang]}}</h4> -->
						<div v-if="opcion.datatipus=='cat'||indicador.datatipus=='cat'">
								<label v-for="(valor, index) in indicador.valores">
									<input type="checkbox" :name="opcion.codename" :value="valor.codename" @change="filtrar(indicador.key,opcion.codename,indicador.name,'cat')" checked>
									<span v-html="textos_lang.indicadores[indicador.key].valores[index][lang]"></span>
									<div class="checkbox-input"><font-awesome-icon icon="circle" /></div>
								</label>
								
								<hr>
								<label>
									<input type="checkbox" class="checkall" :class="opcion.codename" @change="checkall($event,indicador.key,opcion.codename, indicador.name, opcion.datatipus)" checked>
									<span>{{textos_lang.filtres.seltodo[lang]}}</span>
									<div class="checkbox-input"><font-awesome-icon icon="circle"/></div>
								</label>
						</div>
						<template v-else-if="opcion.datatipus=='abs'|| indicador.datatipus=='abs'"> 
							<div  :id="'SL'+opcion.codename"></div>
							<label class="input-filtre" :class="'input'+indicador.codename">{{textos_lang.filtres.max[lang]}} <input type="number" :id="'max' + opcion.codename" class="maxinput"/></label>
							<label class="input-filtre" :class="'input'+indicador.codename">{{textos_lang.filtres.min[lang]}} <input type="number" :id="'min' + opcion.codename" class="mininput"/></label>
							
						</template>
					</div>
					<h4 class="subtitle">{{textos_lang.filtres.opciones[lang]}}</h4>
					<div v-show="indicador.tipopcions.indexOf('valor')!==-1">
						<h6>{{textos_lang.filtres.val[lang]}}</h6>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'valor'" value="0" v-model="indicador.filtreOpcions.valor" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.cat[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'valor'" value="1" v-model="indicador.filtreOpcions.valor" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.abs[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						
					</div>
					<div v-show="indicador.tipopcions.indexOf('sup')!==-1">
						<h6>{{textos_lang.filtres.uni[lang]}}</h6>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'sup'" value="0" v-model="indicador.filtreOpcions.sup" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.tot[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'sup'" value="1" v-model="indicador.filtreOpcions.sup" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.sobre[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'sup'" value="2" v-model="indicador.filtreOpcions.sup" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.sota[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						
					</div>
					<div v-show="indicador.tipopcions.indexOf('consum')!==-1">
						<h6>{{textos_lang.filtres.con[lang]}}</h6>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'consum'" value="0" v-model="indicador.filtreOpcions.consum" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.teor[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'consum'" value="1" v-model="indicador.filtreOpcions.consum" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.real[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label v-if="indicador.tipopcions.indexOf('comparativa')!==-1">
							<input type="radio" :name="'filtro'+indicador.key+'consum'" value="2" v-model="indicador.filtreOpcions.consum" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.comp[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						
						
					</div>
					<div v-show="indicador.tipopcions.indexOf('llindar')!==-1">
						<h6>{{textos_lang.filtres.llin[lang]}}</h6>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'llindar'" value="0" v-model="indicador.filtreOpcions.llindar" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.conf[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'llindar'" value="1" v-model="indicador.filtreOpcions.llindar" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.sal[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						
					</div>
					<!-- si el indicador tiene hipotesi y el consumo no es 'comparativa'-->
					<div v-show="indicador.tipopcions.indexOf('hipotesi')!==-1&&indicador.filtreOpcions.consum!=2">
						<h6 v-if="indicador.tipopcions.indexOf('consum')!==-1&&indicador.indicadorOpcions.consum==1||indicador.key==34">{{textos_lang.filtres.hip[lang]}}</h6>
						<h6 v-else>{{textos_lang.filtres.hip[lang]}}</h6>
						<!--  si no se da que el indicador es 34 y la unidad no es immb -->
						<label v-if="!(indicador.key==34&&indicador.filtreOpcions.unitat!=0)&&!(indicador.key==29&&indicador.filtreOpcions.unitat!=0&&indicador.filtreOpcions.consum==1)">
							<input type="radio" :name="'filtro'+indicador.key+'hipotesi'" value="0" v-model="indicador.filtreOpcions.hipotesi" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.ele[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label v-if="!(indicador.key==34&&indicador.filtreOpcions.unitat!=0&&!(indicador.key==29&&indicador.filtreOpcions.unitat!=0&&indicador.filtreOpcions.consum==1))">
							<input type="radio" :name="'filtro'+indicador.key+'hipotesi'" value="1" v-model="indicador.filtreOpcions.hipotesi" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.gas[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label v-if="indicador.key==34">
							<template v-if="indicador.filtreOpcions.unitat!=0">
								<input type="radio" :name="'filtro'+indicador.key+'hipotesi'" value="2" v-model="indicador.filtreOpcions.hipotesi" :id="indicador.key" @change="showFiltres2($event)" checked>
								<span>{{textos_lang.filtres.tot[lang]}}</span>
								<div class="radio-input checked"></div>
							</template>
							<template v-else>
								<input type="radio" :name="'filtro'+indicador.key+'hipotesi'" value="2" v-model="indicador.filtreOpcions.hipotesi" :id="indicador.key" @change="showFiltres2($event)">
								<span>{{textos_lang.filtres.tot[lang]}}</span>
							<div class="radio-input"></div>
							</template>
						</label>
						<label v-if="indicador.key==29&&indicador.filtreOpcions.consum==1">
							<template v-if="indicador.filtreOpcions.unitat!=0">
								<input type="radio" :name="'filtro'+indicador.key+'hipotesi'" value="2" v-model="indicador.filtreOpcions.hipotesi" :id="indicador.key" @change="showFiltres2($event)" checked>
								<span>{{textos_lang.filtres.tot[lang]}}</span>
								<div class="radio-input checked"></div>
							</template>
							<template v-else>
								<input type="radio" :name="'filtro'+indicador.key+'hipotesi'" value="2" v-model="indicador.filtreOpcions.hipotesi" :id="indicador.key" @change="showFiltres2($event)">
								<span>{{textos_lang.filtres.tot[lang]}}</span>
							<div class="radio-input"></div>
							</template>
						</label>
						
					</div>
					<div v-show="indicador.tipopcions.indexOf('unitat')!==-1&&indicador.filtreOpcions.consum!=2">
						<h6>{{textos_lang.filtres.uni[lang]}}</h6>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'unitat'" value="0" v-model="indicador.filtreOpcions.unitat" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.pi[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'unitat'" value="1" v-model="indicador.filtreOpcions.unitat" :id="indicador.key" @change="showFiltres2($event)">
							<span>{{textos_lang.filtres.ph[lang]}}</span>
							<div class="radio-input"></div>
						</label>
						<label>
							<input type="radio" :name="'filtro'+indicador.key+'unitat'" value="2" v-model="indicador.filtreOpcions.unitat" :id="indicador.key" @change="showFiltres2($event)">
							<span v-html="textos_lang.filtres.pm[lang]"></span>
							<div class="radio-input"></div>
						</label>
						
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '../store/store'
import noUiSlider from 'nouislider'
import 'nouislider/distribute/nouislider.css'
import wNumb from 'wnumb'
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import Crearseleccio from './Crearseleccio.vue'
export default {

  name: 'Filtres',
  data(){
  		return {
  			opcions:{
  				'valor':0,
  				'sup':0,
  				'llindar':0,
  				'hipotesi':0,
  				'unitat':0
  			}
  		}
  },
  computed:{ 
	  	...Vuex.mapState(['indicadores', 'filtros','vista','indcomp','currentZoom','unitatVis','indicadorSeleccionado','textos_lang','lang','escenariSeleccionado']),
	  	...Vuex.mapGetters(['indicadorsArq','indicadorsEner','indicadorsEco', 'currentApp', 'currentLista', 'currentFiltros','currentVista', 'indicador_actual'])
	  },
  methods: { 
  		checkall(event,key,codename,name,datatipus){
  			var all = event.target.checked;
    		var inputs = document.querySelectorAll(`input[name='${codename}']`);
    		inputs.forEach(input=> input.checked=all);
    		this.filtrar(key,codename,name,datatipus)
  		},
		showFiltres(event){
			//mostramos la lista de los filtros de la categoría seleccionada (arq,ener,eco) y se crean los sliders de TODOS los filtros absolutos
			if(document.querySelector('.noUi-target')== null){
				this.createSlider()
			}
			var tots= document.getElementsByClassName('filtres');
			for(var t=0;t < tots.length; t++){
				tots[t].style.display='none';
			}
			var targetId = event.currentTarget.id;
			this.$refs.menufiltres.style.transform = 'translateX(-250px)';
			var refs = this.$refs;
			Object.entries(refs).forEach(entry => {
				if(entry[0]==targetId) {
					entry[1].style.display='block';
				}
			});
		},
		showFiltres2(event){
			if(document.querySelector('.noUi-target')== null){
				this.createSlider()
			}
			//oculto todos los filtros
			var tots= document.querySelectorAll('.lista-filtre');
			tots.forEach(lista => lista.style.display='none')
			//oculto todas las listas de opciones de los filtros
			var menusopt = document.querySelectorAll('.lista-opcions')
			menusopt.forEach(menu => menu.style.display='none')
			//cojo la id del filtro seleccionado y el indicador correspondiente (event es una cosa u otra según de donde vengo)
			var targetId;
			if(typeof event.currentTarget!='undefined') {
				targetId = event.currentTarget.id
			} else if(typeof event.opt!='undefined') {
				targetId = event.ind
			} else {
				targetId = event;
			}
			var indicadorFiltro = store.state.indicadores.find(indicador => indicador.key == targetId);
			//muevo el menú filtres hacia la izquierda par ver la columna correspondiente
			this.$refs.menufiltres.style.transform = 'translateX(-500px)';
			//cojo todas las $refs (que son las diferentes opciones, de momento ocultas)
			var refs = this.$refs;
			var tipo;
			//me guardo el tipo de opciones (si las tiene)
			if(indicadorFiltro.tipopcions){
				tipo = indicadorFiltro.tipopcions
			}
			//cojo el objeto opcions que he creado en este componente
			var opcions = this.opcions;
			//preparo lo que voy a enviar a la mutation del store y lo envío
			var payload = {opcions, indicadorFiltro}
			//cambio el objeto opcionsFiltre del indicador por las que le envío (que han sido modificadas por el usuario)
			store.commit('set_filtreOpcions', payload)
			var opcion;
			//cojo el objeto de las opciones del indicador (que acabo de modificar)
			var opciones = indicadorFiltro.filtreOpcions;
			//determino qué opción es la elegida según el tipo de opciones y los valores dentro del objeto "opciones"
			if(tipo=='valor'){
				opcion = opciones.valor
			} else if (tipo=='sup'){
				opcion = opciones.sup
			} else if (tipo=='unitat'){
				opcion = opciones.unitat
			}else if (tipo == 'llindar'){
				opcion = opciones['llindar']
			} else if (tipo == 'llindar_unitat_valor'){
				if(opciones.valor==0){
					if(opciones.llindar ==0){
						opcion = opciones['unitat']
					} else if(opciones.llindar == 1){
						opcion = parseInt(opciones['unitat']) + 3
					}
				} else if(opciones.valor==1){
					if(opciones.llindar ==0){
						opcion = parseInt(opciones['unitat']) + 6
					} else if(opciones.llindar == 1){
						opcion = parseInt(opciones['unitat']) + 9
					}
				}
			} else if (tipo == 'hipotesi'){
					opcion = opciones['hipotesi']
			} else if (tipo == 'consum'){
					opcion = opciones['consum']
			}else if (tipo == 'hipotesi_llindar_unitat'){
				if(opciones.hipotesi==0){
					if(opciones.llindar ==0){
						opcion = opciones['unitat']
					} else if(opciones.llindar == 1){
						opcion = parseInt(opciones['unitat']) + 3
					}
				} else if(opciones.hipotesi==1){
					if(opciones.llindar ==0){
						opcion = parseInt(opciones['unitat']) + 6
					} else if(opciones.llindar == 1){
						opcion = parseInt(opciones['unitat']) + 9
					}
				}
			} else if (tipo == 'hipotesi_llindar'){
				if(opciones.hipotesi==0){
					
						opcion = parseInt(opciones['llindar'])
					
				} else if(opciones.hipotesi==1){
					opcion = parseInt(opciones['llindar']) + 2
				}
			} else if (tipo == 'hipotesi_unitat'){
				if(opciones.hipotesi==0){
					
						opcion = parseInt(opciones['unitat'])
					
				} else if(opciones.hipotesi==1){
					opcion = parseInt(opciones['unitat']) + 2
				}
			} else if (tipo == 'hipotesi_unitat3'){
				if(opciones.unitat==0){
					
						opcion = parseInt(opciones['hipotesi'])
					
				} else if(opciones.unitat==1){
					opcion =  3
				} else if(opciones.unitat==2){
					opcion =  4
				}
			} else if (tipo == 'consum_hipotesi_unitat'){
				if(opciones.consum==0){
					if(opciones.hipotesi ==0){
						opcion = opciones['unitat']
					} else if(opciones.hipotesi == 1){
						opcion = parseInt(opciones['unitat']) + 3
					}
				} else if(opciones.consum==1){
					if(opciones.hipotesi ==0){
						opcion = parseInt(opciones['unitat']) + 6
					} else if(opciones.hipotesi == 1){
						opcion = parseInt(opciones['unitat']) + 9
					}
				}
			} else if (tipo == 'consum_hipotesi_unitat_comparativa'){
				if(opciones.consum==0){
					if(opciones.hipotesi ==0){
						opcion = opciones['unitat']
					} else if(opciones.hipotesi == 1){
						opcion = parseInt(opciones['unitat']) + 3
					}
				} else if(opciones.consum==1){
					if(opciones.unitat==0){
						opcion = parseInt(opciones['hipotesi']) + 6
					} else if(opciones.unitat==1){
						opcion = 9
					} else if(opciones.unitat==2){
						opcion = 10
					}
				} else if(opciones.consum==2){
					opcion = 11
				}
			}else if (tipo == 'consum_hipotesi'){
				if(opciones.consum==0){
					
						opcion = parseInt(opciones['llindar'])
					
				} else if(opciones.consum==1){
					opcion = parseInt(opciones['hipotesi']) + 2
				}
			}
			if(event.opt) {
				opcion = event.opt
			}
			//muestro el indicador que toca y la opción que toca
			Object.entries(refs).forEach(entry => {
				if(entry[0]==targetId) {
					entry[1][0].style.display='block';
				}
				if(entry[0]==`opcion${targetId}`){
					entry[1][0].style.display='block'
				}
				if(entry[0]==`ind${targetId}opt${opcion}`){
					entry[1][0].style.display='block'
				}
			});

		},
		showFiltres3(key,event){
			var tots= document.querySelectorAll('.lista-filtre');
			tots.forEach(lista => lista.style.display='none')
			var menusopt = document.querySelectorAll('.lista-opcions')
			menusopt.forEach(menu => menu.style.display='none')
			var targetId = key;
			var indicadorFiltro = store.state.indicadores.find(indicador => indicador.key == targetId);
			this.$refs.menufiltres.style.transform = 'translateX(-500px)';
			var refs = this.$refs;
			var tipo;
			if(indicadorFiltro.tipopcions){
				tipo = indicadorFiltro.tipopcions
			}
			var opcions = this.opcions;
			var payload = {opcions, indicadorFiltro}
			store.commit('set_filtreOpcions', payload)
			var opcion= event.currentTarget.id;
			var opciones = indicadorFiltro.filtreOpcions;
			document.querySelector(`input[name="filtro${targetId}${tipo}"][value="${opcion}"]`).click()
			Object.entries(refs).forEach(entry => {
				if(entry[0]==targetId) {
					entry[1][0].style.display='block';
				}
				if(entry[0]==`opcion${targetId}`){
					entry[1][0].style.display='block'
				}
				if(entry[0]==`ind${targetId}opt${opcion}`){
					entry[1][0].style.display='block'
				}
			});

		},
		showDibuix(event) {
			var tots= document.querySelectorAll('.lista-filtre');
			tots.forEach(lista => lista.style.display='none');
			var menusopt = document.querySelectorAll('.lista-opcions');
			menusopt.forEach(menu => menu.style.display='none');
			this.$refs.menufiltres.style.transform = 'translateX(-500px)';
			document.querySelector('.dibuix').style.display='block';
			const map = this.$parent.$parent.$refs.mapa.$children[0].map;
			if(!document.querySelector('.mapbox-gl-draw_ctrl-draw-btn')){
    			store.dispatch('einaDibuix',{map})
			} else {
    			var elem = document.querySelector('.einaDibuix')
				elem.style.display = 'block';
				store.state.draw.changeMode('simple_select')
    		}
    		var tooltips = document.querySelectorAll('.tooltip')
    		tooltips.forEach(tip => tip.style.display = 'none')
			
		},
		showMunicipis(event) {
			var tots= document.querySelectorAll('.lista-filtre');
			tots.forEach(lista => lista.style.display='none');
			var menusopt = document.querySelectorAll('.lista-opcions');
			menusopt.forEach(menu => menu.style.display='none');
			this.$refs.menufiltres.style.transform = 'translateX(-500px)';
			document.querySelector('.municipis').style.display='block';
			
			
		},
		showSeccions(event){
			//oculto los demás filtros y muestro Seccions
			var tots= document.querySelectorAll('.lista-filtre');
			tots.forEach(lista => lista.style.display='none');
			var menusopt = document.querySelectorAll('.lista-opcions');
			menusopt.forEach(menu => menu.style.display='none');
			this.$refs.menufiltres.style.transform = 'translateX(-500px)';
			document.querySelector('.seccions').style.display='block';
			//Añado dos capas de secciones, una para el relleno y otra para los bordes
			const mapa = this.$parent.$parent.$refs.mapa.$children[0].map;
			var layerseccions = mapa.getLayer('seccionsfill');
			if(typeof layerseccions !== 'undefined'){
				var capa = 'seccionsfill';
				var opcion = 'mostrar';
				store.dispatch('mostrarOcultar',{mapa,capa,opcion});
				capa = 'seccionsline';
				store.dispatch('mostrarOcultar',{mapa,capa,opcion});
				mapa.moveLayer('seccionsfill', store.state.textos);
				mapa.moveLayer('seccionsline', store.state.textos);
				if(store.getters.currentFilteredSeccions&&store.getters.currentFilteredSeccions.length>0){
					mapa.setPaintProperty('seccionsfill', 'fill-opacity', 
						['match',
						['get','er'],
						
						store.getters.currentFilteredSeccions, 0,0.5])	
				} else {
					mapa.setPaintProperty('seccionsfill', 'fill-opacity', 0.5)
				}
			} else {
				mapa.addLayer(store.state.capaSeccionsFill,store.state.textos);
				mapa.addLayer(store.state.capaSeccionsLine,store.state.textos);		
			}
			
			
			
			
		},
		undoDibuix(){
			this.$refs.menufiltres.style.transform = `translateX(-250px)`;
			const map = this.$parent.$parent.$refs.mapa.$children[0].map;
			var elem = document.querySelector('.einaDibuix')
			elem.style.display='none';
			var draw = store.state.draw;
			draw.changeMode('static');
			var tooltips = document.querySelectorAll('.tooltip')
    		tooltips.forEach(tip => tip.style.display = 'block')
		},
		undoSeccions(){
			this.$refs.menufiltres.style.transform = `translateX(-250px)`;
			const mapa = this.$parent.$parent.$refs.mapa.$children[0].map;
			var capa = 'seccionsfill';
			var opcion = 'ocultar';
			store.dispatch('mostrarOcultar',{mapa,capa,opcion});
			capa = 'seccionsline';
			store.dispatch('mostrarOcultar',{mapa,capa,opcion});
			var boolean = true;
			var map = mapa;
		    //store.dispatch('addPopup',{map,boolean})
		},
		resetLeftbar(num) {
			this.$refs.menufiltres.style.transform = `translateX(${num})`;
		},
		filtrar(key,codename,name,datatipus){
			
			const map = this.$parent.$parent.$refs.mapa.$children[0].map;
			var valors = []
			var values = document.querySelectorAll(`input[name="${codename}"]:checked`);
			var valuesNo= document.querySelectorAll(`input[name="${codename}"]:not(:checked)`);
			if(valuesNo.length==0){
				valors='todos'
				if(this.indcomp[key]){
					document.querySelector(`#SL${this.indcomp[key][0].abs}`).removeAttribute('disabled')
					document.querySelector(`#min${this.indcomp[key][0].abs}`).disabled = false;
					document.querySelector(`#max${this.indcomp[key][0].abs}`).disabled = false
				}
			} else {
				values.forEach(val=>{
					var value = val.value;
					if(val.value.indexOf(',')>=0){
						value = value.split(',')
						value.forEach(x=>valors.push(x))
					} else { valors.push(value)}
				})
				if(this.indcomp[key]){
					document.querySelector(`#SL${this.indcomp[key][0].abs}`).setAttribute('disabled',true);
					document.querySelector(`#min${this.indcomp[key][0].abs}`).disabled = true;
					document.querySelector(`#max${this.indcomp[key][0].abs}`).disabled = true
				}
			}
			store.commit('change_filtros', {valors,codename,datatipus});
			if(map.getLayer('indextra')!=undefined){
				map.setFilter('indextra', this.currentFiltros)
			}
			map.setFilter('uzeb', this.currentFiltros)
			
		},
		borrarfiltro(codename, name, datatipus, key){

			const map = this.$parent.$parent.$refs.mapa.$children[0].map;
			if(codename=='ej'){
				
				var draw = store.state.draw;
				var ids = []
				draw.deleteAll()
				var filindex = this.currentFiltros.indexOf(this.currentFiltros.filter(fil => fil[1]=='ej')[0]);
			    if(filindex!=-1){
			    	this.currentFiltros.splice(filindex,1)
			    }
			    map.setFilter("uzeb", this.currentFiltros);
			    var index = store.getters.currentLista.indexOf('ej');
			    store.getters.currentLista.splice(index, 1)
			    store.state.poligonosDibujados = [];
			    store.state.puntosDibujados = [];
			    store.state.puntosEliminados = [];
				
				store.state.filteredFeatures = []
			} else if(codename=='er'){
				store.state.filteredSeccions = [];
				var valors = 'todos'
				var datatipus = 'cat';
				store.commit('change_filtros', {valors,codename,datatipus});
			    map.setFilter("uzeb", this.currentFiltros);
			    
			} else {
				var tocheck = document.getElementsByName(codename);
				if(tocheck.length>0){
					for (var i=0; i<tocheck.length;i++){
							tocheck[i].checked = true
					}
				} else {
					var slider = document.querySelector(`#SL${codename}`);
					slider.noUiSlider.reset()	
				}
				var valors = 'todos'
				store.commit('change_filtros', {valors,codename,datatipus});
				map.setFilter('uzeb', this.currentFiltros)
				if(this.indcomp[key]){
					document.querySelector(`#SL${this.indcomp[key][0].abs}`).removeAttribute('disabled')
					document.querySelector(`#min${this.indcomp[key][0].abs}`).disabled = false;
					document.querySelector(`#max${this.indcomp[key][0].abs}`).disabled = false
				}
			}
		},
		createSlider(){
			const map = this.$parent.$parent.$refs.mapa.$children[0].map;
			store.dispatch('crearSliders', map)
		},
		modalseleccion(){
			var modalcontainer = document.createElement('div')
			modalcontainer.setAttribute('id','selecciomodal')
			document.querySelector('#app').appendChild(modalcontainer)
			new Crearseleccio({
			        	store,
			        }).$mount('#selecciomodal')
		},
		borrartodos(){
			store.state.filtros = ['all',];
			const map = this.$parent.$parent.$refs.mapa.$children[0].map;
			map.setFilter('uzeb', this.currentFiltros)
			if(this.currentApp.tipo=='open'){
				var sliders = document.querySelectorAll('.noUi-target')
				sliders.forEach(slide=> slide.removeAttribute('disabled'))
				var mins = document.querySelectorAll('.mininput')
				mins.forEach(inp =>inp.disabled = false);
				var maxs = document.querySelectorAll('.maxinput')
				maxs.forEach(inp =>inp.disabled = false);
				var checks=document.querySelectorAll('.menu-filtres input[type="checkbox"')
				checks.forEach(inp=>inp.checked='checked')
			}
			
		}
  },
  mounted(){
  	//this.createSlider()
  	if(this.currentApp.tipo == 'open'){
  		var _this = this;
  		setTimeout(function(){_this.showFiltres2(_this.indicadorSeleccionado)},2000)
  	}
  }
 
}
</script>

<style lang="scss">

</style>
