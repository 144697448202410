import { mapState } from 'vuex'
export default {
  computed:{ 
    ...mapState(['mapelement'])
  },
  methods: {
    fitCityBounds: function(bounds) {
      this.mapelement.fitBounds(bounds)
    }
  }
}