<template>
	<button class="boton" id="info" :class="[{rightclose: !rightSidebar}, currentApp.tipo]" @click="showHide()" v-if="currentApp.tipo!='open'"><font-awesome-icon icon="info" /></button>
</template>

<script>
import { store } from '../store/store'
import Vuex from 'vuex'
export default {

  name: 'Topright',
  computed: {
  	...Vuex.mapState(['rightSidebar', 'leftSidebar']),
  	...Vuex.mapGetters(['currentApp'])
  },
  methods: {
		showHide(){
			//cambio true/false rightSidebar
			store.commit('toggle_Sidebar', 'rightSidebar');
			if(window.innerWidth<=600&&this.leftSidebar){
				store.commit('toggle_Sidebar', 'leftSidebar');
				document.querySelector('.mapboxgl-ctrl-bottom-left').style.left='0px';
     		}
			if(store.state.rightSidebar){
				document.querySelector('.mapboxgl-ctrl-bottom-right').classList.remove('rightclose')
			} else {
				document.querySelector('.mapboxgl-ctrl-bottom-right').classList.add('rightclose')
			}
		}
	}
}
</script>

<style lang="scss">

</style>