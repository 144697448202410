var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mapbox',{class:_vm.currentApp.tipo,attrs:{"access-token":_vm.accessToken,"map-options":{
        style: this.estilo,
        center: _vm.currentApp.mapAttributes.center,
        zoom: _vm.currentApp.mapAttributes.zoom,
        hash:false,
        maxZoom:18,
        attributionControl:true,
       

      },"scale-control":{
        show: true,
        position: 'bottom-right',
      },"nav-control":{
  		show: true,
  		position: 'bottom-right',
  		options:{
			visualizePitch:true
  		}
  	  }},on:{"map-load":_vm.setMap}})}
var staticRenderFns = []

export { render, staticRenderFns }