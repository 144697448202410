<template>
	<mapbox
      :access-token=accessToken
      :map-options="{
        style: this.estilo,
        center: currentApp.mapAttributes.center,
        zoom: currentApp.mapAttributes.zoom,
        hash:false,
        maxZoom:18,
        attributionControl:true,
       

      }"
      :scale-control="{
        show: true,
        position: 'bottom-right',
      }"
      :nav-control="{
  		show: true,
  		position: 'bottom-right',
  		options:{
			visualizePitch:true
  		}
  	  }"
      @map-load='setMap'
      :class="currentApp.tipo"
    />
	
</template>

<script>
	import Vue from 'vue'
	import Vuex from 'vuex'
	import Mapbox from 'mapbox-gl-vue'
  	import { store } from '../store/store'
  	import axios from 'axios'
  	import Vueaxios from 'vue-axios'
  	import PopupContent from './Popupcontent.vue'
  	import dataestilo from '../assets/stylelight.json?optimize=true'
  	import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
  	import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
  	import * as turf from 'turf'
	export default {

	  name: 'Mapa',
	  components: {Mapbox},
	  data() {
	  	return{ estilo: dataestilo}
	  },
	  computed:{ 
	  	...Vuex.mapState(['pitch','accessToken','lightStyle','estiloIndicador','indicadorSeleccionado', 'currentApp']),
	  	...Vuex.mapGetters(['indicador_actual', 'currentApp'])
	  },
	  methods: {
	  	setMap (map) {
        //todo esto ocurre una vez se ha cargado el mapa
	  		//creo el elemento map en store
	  		store.state.mapelement = map;
	  		//guardamos la capa de textos
	  		store.commit('set_textos',map);
	  		//añadimos la capa 'uzeb', con toda la información, debajo de la capa de textos
			  map.addLayer(store.state.capaUzeb, store.state.textos);
			   //añadimos la capa de fondo debajo de 'uzeb'
	  		map.addLayer(store.state.capaFondo,'uzeb');
	  		//compruebo si hay parametros en la url y hacemos los cambios necesarios en el mapa
	  		let params = new URLSearchParams(document.location.search.substring(1));
	  		if(params.get('i')){
      			store.commit('change_indicador', parseInt(params.get('i')))
      			if(this.currentApp.mapas){
					store.commit('cambio_source')
				}
      		}
      		if(params.get('b')){
      	var bounds = params.getAll('b')
				
      			map.fitBounds(bounds)
      		}
      		if(params.get('be')){
      			
				var bearing = params.getAll('be')
				
      			map.setBearing(bearing)
      		}
      		if(params.get('pi')){
      			
				var pitch = params.getAll('pi')
				
      			map.setPitch(pitch)
      		}
      		if(params.get('z')){
      			
				var zoom = params.getAll('z')
				
      			map.setZoom(zoom)
      		}
      	//modificamos el elemento que determinará el estilo de la capa 'uzeb' según el indicador seleccionado (por defecto: Ús principal)
	  		store.commit('change_estiloIndicador', map)
	  		//aplicamos el estilo a la capa 'uzeb'
	  		map.setPaintProperty('uzeb','fill-color',store.state.estiloIndicador)
		    var boolean = true;
		    //añadimos el popup de las referencias
		    store.dispatch('addPopup',{map,boolean})
		    //nos volvemos a guardar el mapa con sus modificaciones
		    store.state.mapelement = map;

		    store.state.currentZoom = map.getZoom();
		    //desactivamos los botones de la herramienta de dibujo si el zoom es inferior a 13
		    map.on('zoom', function(){
		    	store.state.currentZoom = map.getZoom();
		    	var buttons = document.querySelectorAll('.einaDibuix .mapbox-gl-draw_ctrl-draw-btn')
		    	if(buttons){
		    		if(store.state.currentZoom<13){
    					buttons.forEach(but => but.disabled = true)
    				} else {
    					buttons.forEach(but => but.disabled = false)
    				}
		    	}
    			
		    });
		    //configuro el estilo y el ratón al pasar por encima de una sección censal (y al salir)
  			map.on('mousemove', 'seccionsfill', function(e) {
  				if (e.features.length > 0) {
  					
  					if(store.getters.currentFilteredSeccions&&store.getters.currentFilteredSeccions.length>0){
  						map.setPaintProperty('seccionsfill', 'fill-opacity', 
  							['match',
  							['get','er'],
  							e.features[0].properties.er, 
  							0,
  							store.getters.currentFilteredSeccions, 0,0.5])	
  					} else {
  						map.setPaintProperty('seccionsfill', 'fill-opacity', ['match',
  							['get','er'],
  							e.features[0].properties.er, 
  							0,
  							0.5])
  					}
  					
  					map.getCanvas().style.cursor = 'pointer';
  				}
  			});
  			map.on('mouseleave', 'seccionsfill', function() {
  				if(store.getters.currentFilteredSeccions&&store.getters.currentFilteredSeccions.length>0){
  					map.setPaintProperty('seccionsfill', 'fill-opacity', ['match',
  							['get','er'],
  							store.getters.currentFilteredSeccions, 0,0.5]);
  				}else{
  					map.setPaintProperty('seccionsfill', 'fill-opacity', 0.5);
  				}
  				
  				map.getCanvas().style.cursor = '';
  			});
  			//evento al clicar sobre una sección censal
  			//configuro el filtro al hacer click
  			map.on('click', 'seccionsfill', function(e){
  				if (e.features.length > 0) {
  					//guardamos la id de la sección censal
  					var selid = e.features[0].properties.er
  					var filteredSeccions;

  					//si estoy viendo un ámbito personalizado del usuario
  					if(store.state.vista){
  						if(store.state.seleccions.find(sel => sel.id_sel == store.state.vista).filteredSeccions==null){
  							store.state.seleccions.find(sel => sel.id_sel == store.state.vista).filteredSeccions = []
  						}
  						filteredSeccions = store.state.seleccions.find(sel => sel.id_sel == store.state.vista).filteredSeccions
  					} else {
  						filteredSeccions = store.state.filteredSeccions;
  					}
  					var codename = 'er';
  					var datatipus = 'cat';
  					//añadimos la id de la sección al array filteredSeccions o la eliminamos si ya está
  					//modificamos los filtros del mapa
  					if(!filteredSeccions.includes(selid)){
  						filteredSeccions.push(selid);
  						var valors = [...filteredSeccions];
  						store.commit('change_filtros', {valors,codename,datatipus});
  					} else {
  						var ind = filteredSeccions.indexOf(selid);
  						filteredSeccions.splice(ind,1);
  						if(filteredSeccions.length==0){
  							var valors = 'todos';
  							store.commit('change_filtros', {valors,codename,datatipus});
  						} else {
  							var valors = [...filteredSeccions];
  							store.commit('change_filtros', {valors,codename,datatipus});
  						}
  					}
  					//filtramos el mapa
  				map.setFilter('uzeb', store.getters.currentFiltros)
  				}

  				
  			});
			  //si estoy en la demo hago zoom a Ciutadella
        if(this.currentApp.key==8&&document.querySelector('#intro')==undefined){
          setTimeout(function(){
            const bounds = [[3.8654147, 40.0114616],[3.80851147,39.988094]]
            map.fitBounds(bounds)
          },2000)
        } 
		    
		  },
		
	  },
	  
	}
</script>

<style lang="scss">

</style>
