<template>
	<div class="menu" id="menu">
		<!-- <router-link v-for="item in menuItems" class="menu-item" :class="item.class" @mouseenter.native="hover(item.key)" @mouseleave.native="leave()" v-html="item.title"  :ref="item.class" :href="item.link" :to="'/'+item.link"></router-link>
		<div class="bar-active" ref="barra"></div> -->
		<router-link class="boton" to="/es" v-if="lang=='ca'">
			<span>{{textos_lang.botonesbottom.botones[3][lang]}}</span>
			<transition name="fadein"><span class="tooltip" v-if="!$isMobile()" v-html="textos_lang.botonesbottom.botones[4][lang]"></span></transition>
		</router-link>
		<router-link class="boton" to="/ca" v-if="lang=='es'">
			<span>{{textos_lang.botonesbottom.botones[3][lang]}}</span>
			<transition name="fadein"><span class="tooltip" v-if="!$isMobile()" v-html="textos_lang.botonesbottom.botones[4][lang]"></span></transition>
		</router-link>
  		<a class="boton" href=""><font-awesome-icon icon="sign-out-alt" /></a>
   </div>
</template>
<script>
	import Vue from 'vue'
	import VueRouter from 'vue-router'
	import Vuex from 'vuex'
	import { store } from '../store/store'
	Vue.use(VueRouter)
	export default {
		computed: {
			...Vuex.mapState(['menuItems', 'textos_lang','lang']),
		},
		watch: {
		    '$route' (to, from) {
		      store.state.lang = this.$route.params.lang
		     
		    }
		 },
		methods: {
	  		/*hover (key) {
	  			this.$refs.barra.style.width=this.$el.children[key].offsetWidth  + 'px'
	      		this.$refs.barra.style.left=this.$el.children[key].offsetLeft  + 'px'
	    	},
	    	leave () {
	    		this.$refs.barra.style.width=this.$refs.active[0].offsetWidth  + 'px';
				this.$refs.barra.style.left=this.$refs.active[0].offsetLeft  + 'px';
	    	},*/
	    	idioma(){
		  		if(store.state.lang=='ca'){
		  			store.state.lang = 'es';
		  			this.$route.params.lang='es'
		  		} else {
		  			store.state.lang = 'ca'
		  		}
		  	}
	  	},
	  	mounted: function(){
	  		/*this.leave()*/
	  	}
	}
</script>
<style lang="scss">

	
</style>