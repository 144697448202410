<template>
	<transition name="fadein">
		<div class="modal-mask modalsel">
      		<div class="modal-wrapper">
      			<div class="modal-container">
        			<div class="closemodal">
          				<button class="boton sub-boton" @click="close()"><font-awesome-icon icon="times" /></button>
        			</div>
	        		<div v-if="opcio=='editar'">
	        			<div class="crearsel selformcont">
							<h4>{{textos_lang.modalambit.editar[lang]}}</h4>
							<form>
		              			<div class="alerta" v-if="alerta" v-html="alerta"></div>
								<input name="namesel" type="text" placeholder="Nom" :value="seleccio.nom_sel"required>
								<textarea name="dessel" placeholder="Descripció">{{seleccio.desc_sel}}</textarea>
								<button type="submit" @click.prevent="updateSel()" class="botontexto">{{textos_lang.modalambit.guardar[lang]}} <span class="boton"><font-awesome-icon icon="save" /></span></button>
							</form>
						</div>
	        		</div>
	        		<div v-if="opcio=='duplicar'">
	        			<div class="crearsel selformcont">
							<h4>{{textos_lang.modalambit.duplicar[lang]}} {{seleccio.nom_sel}}</h4>
							<form>
		              			<div class="alerta" v-if="alerta" v-html="alerta"></div>
								<input name="namesel" type="text" placeholder="Nom" :value="seleccio.nom_sel"required>
								<textarea name="dessel" placeholder="Descripció">{{seleccio.desc_sel}}</textarea>
								<button type="submit" @click.prevent="duplicateSel()" class="botontexto">{{textos_lang.modalambit.guardar[lang]}} <span class="boton"><font-awesome-icon icon="save" /></span></button>
							</form>
						</div>
	        		</div>
	        		<div v-if="opcio=='eliminar'">
						<div class="crearsel selformcont">
							<h4>{{textos_lang.modalambit.seguro[lang]}}</h4>
							<div class="sino">
								<button class="botontexto" @click="close()">{{textos_lang.modalambit.cancelar[lang]}} <span class="boton sub-boton"><font-awesome-icon icon="times" /></span></button>
								<button class="botontexto" @click="removeSel()">{{textos_lang.modalambit.eliminar[lang]}} <span class="boton sub-boton"><font-awesome-icon icon="trash-alt" /></span></button>
							</div>
						</div>
	        		</div>
	        		<div v-if="opcio=='verdefinicio'">
	          			<div class="definicio">
	            			<h4>{{textos_lang.modalambit.definicio[lang]}}</h4>
	            			<div  class="tabbuttons">
	              				<button v-for="condicio in seleccio.definicio" :class="{active: tab==condicio.key}" @click="changeTab(condicio.key)">{{textos_lang.modalambit.grupo[lang]}} {{condicio.key}}</button>
	            			</div>
	            			<div v-for="condicio in seleccio.definicio" v-if="tab==condicio.key" class="condicio">
	              				<div class="filtro" v-for="filtro in condicio.filtros">
	              					<template v-if="filtro.name">
										<h5>{{textos_lang.crearSeleccio.nuevo[filtro.name][lang]}}</h5>
										<ul v-if="filtro.valors">
											<li v-for="valor in filtro.valors">{{valor}}</li>
										</ul>
	              					</template>
	              					<template v-else>
	              						<h5>{{textos_lang.indicadores[filtro.key].name[lang]}}</h5>
										<ul v-if="filtro.valors">
		                					<li v-for="valor in filtro.valors">{{textos_lang.indicadores[filtro.key].valores[valor][lang]}}</li>
		                				</ul>
		                				<ul v-if="filtro.rango&&[3,4,5].includes(filtro.key)">
		                					<li v-if="filtro.rango.min">{{textos_lang.filtres.min[lang]}}: {{filtro.rango.min | numeralFormat}}</li>
		                  					<li v-if="filtro.rango.max">{{textos_lang.filtres.max[lang]}}: {{filtro.rango.max | numeralFormat}} </li>
		                				</ul>
		                				<ul v-else-if="filtro.rango">
		                					<li v-if="filtro.rango.min">{{textos_lang.filtres.min[lang]}}: {{filtro.rango.min | numeralFormat('0,000.00')}}</li>
		                  					<li v-if="filtro.rango.max">{{textos_lang.filtres.max[lang]}}: {{filtro.rango.max | numeralFormat('0,000.00')}} </li>
		                				</ul>
	              					</template>
	                				
	              				</div>
	            			</div>
	            			<div v-if="seleccio.leyenda">
	            				<h4>{{textos_lang.modalambit.numrefs[lang]}}</h4>
	            				<ul class="escenaris">
	            					<li v-for="(escenari, index) in escenaris" v-if="escenari.key<6"><strong v-html="textos_lang.escenarios[index][lang] + ': '"></strong><span>{{seleccio.leyenda['bk_'+ escenari.key].num_features | numeralFormat}} {{textos_lang.modalambit.refs[lang]}}</span></li>
	            				</ul>
	            			</div>
	          			</div>
	        		</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Vueaxios from 'vue-axios'
import { store } from '../store/store'
import VueNumerals from 'vue-numerals';
Vue.use(VueNumerals, {
  locale: 'es',
});
export default Vue.extend({ 
  	name: 'Modalambit',
	props: {
	    opcio: {
	      required: true,
	      type: String,
	    },
	    seleccio: {
	      required: true,
	      type: Object,
	    },
	},
	data(){
		return {
			tab:1,
			alerta:false
		}
	},
	computed:{
	  	...Vuex.mapState(['seleccions','indicadors','escenaris','textos_lang','lang']),
	    ...Vuex.mapGetters(['orderSeleccions']),

	},
	methods: {
		close(){
  			document.querySelector('.modalsel').remove()
  		},
  		updateSel() {
	    	var nom = document.querySelector('input[name="namesel"]').value;
	  		var descripcio = document.querySelector('textarea[name="dessel"]').value;
	      	var existe = false;
		    if(this.seleccions.length>0){
		        this.seleccions.forEach(sel=>{
				  if(sel.nom_sel==nom&&sel.id_sel!=this.seleccio.id_sel){
		            existe = true
		          }
		        })
		      }   	
		      if(nom.length>0&&!existe){
		        var data = {
		        	'id_sel':this.seleccio.id_sel,
		          'nom_sel': nom,
		          'desc_sel': descripcio,  
		        }
	        var csrftoken = store.dispatch('getCookie','csrftoken')
	        axios.post(`api/update_name_desc/`, data, {headers:{'X-CSRFToken':csrftoken}})
	        .then(response => {
	        	var selecIndex = store.state.seleccions.indexOf(store.state.seleccions.find(sel=> sel.id_sel==this.seleccio.id_sel))
	         	//store.state.seleccions[selecIndex] = {...response.data.seleccion_object}
	         	store.state.seleccions[selecIndex].nom_sel = response.data.seleccion_object.nom_sel;
	         	store.state.seleccions[selecIndex].desc_sel = response.data.seleccion_object.desc_sel;
	 
	         	
	          //seleccions.find(sel=> sel.id_sel==this.seleccio.id_sel) =response.data.seleccion_object;
	          this.alerta = false;
	          this.close();
	        })
	        .catch(err => {console.log(err);});
	      } else {
	        if(existe) {
	          this.alerta = this.textos_lang.modalambit.alerta1[this.lang]
	        } else {
	          this.alerta = this.textos_lang.modalambit.alerta2[this.lang]
	        }
	      }
	    },
	    duplicateSel() {
	    	var nom = document.querySelector('input[name="namesel"]').value;
	  		var descripcio = document.querySelector('textarea[name="dessel"]').value;
	      	var existe = false;
		    if(this.seleccions.length>0){
		        this.seleccions.forEach(sel=>{
				  if(sel.nom_sel==nom){
		            existe = true
		          }
		        })
		      }   	
		      if(nom.length>0&&!existe){
		        var data = {
		          'filtro':this.seleccio.filtro,
		          'nom_sel': nom,
		          'desc_sel': descripcio,  
		        }
	        var csrftoken = store.dispatch('getCookie','csrftoken')
	        axios.post(`api/create_selection/`, data, {headers:{'X-CSRFToken':csrftoken}})
	        .then(response => {
	         	store.state.seleccions.push(response.data.seleccion_object);
	          this.alerta = false;
	          this.close();
	        })
	        .catch(err => {console.log(err);});
	      } else {
	        if(existe) {
	          this.alerta = this.textos_lang.modalambit.alerta1[this.lang]
	        } else {
	          this.alerta = this.textos_lang.modalambit.alerta2[this.lang]
	        }
	      }
	    },
	    removeSel() {
	    	var selecIndex = store.state.seleccions.indexOf(store.state.seleccions.find(sel=> sel.id_sel==this.seleccio.id_sel))
	      if(store.state.vista==this.seleccio.id_sel){
	        var key='general';
	        store.dispatch('updateAmbit',{key})
	        document.querySelector('input[name="selambit"][value="general"]').checked = true
	      }
	    	var data = {
	          'id_sel': this.seleccio.id_sel
	        }
	        var csrftoken = store.dispatch('getCookie','csrftoken')
	        axios.post(`api/delete_selection/`, data, {headers:{'X-CSRFToken':csrftoken}})
	        .then(response => {
	          console.log(response)
	          store.state.seleccions.splice(selecIndex,1)
	          this.close();
	        })
	        .catch(err => {console.log(err);});
	    },
	    toArray(string){
	      return Array.from(string)
	    },
	    changeTab(key) {
	      this.tab=key;
	    }
	},
	created(){
		
	}
})
</script>

<style lang="css" scoped>
</style>