<template>
	<transition name="slideleft">
		<div class="right-sidebar" :class="currentApp.tipo" v-show="rightSidebar">
			<div class="leyenda">
				<Leyenda/>
				<template v-if="currentApp.tipo=='pro'||currentApp.tipo=='show'">
					<h3 class="titulo-seccio">{{textos_lang.rightbar.ambito[lang]}}</h3>
					<h3 class="subtitulo-seccio" v-if="vista" v-html="currentVista.nom_sel"></h3>
					<h3 class="subtitulo-seccio" v-else>{{textos_lang.rightbar.general[lang]}}</h3>
				</template>
				<template >
					<div class="leyendafiltres" v-if="(!vista&&currentFiltros.length!==1)||(vista&&currentFiltros.length>2)">
						<h3 class="titulo-seccio">{{textos_lang.rightbar.filtros[lang]}}</h3>
						<ul>
							<li v-if="currentLista&&currentLista.includes('ej')">{{textos_lang.rightbar.pers[lang]}}</li>
							<li v-if="currentLista&&currentLista.includes('fa')">{{textos_lang.rightbar.munis[lang]}}</li>
							<li v-if="currentLista&&currentLista.includes('er')">{{textos_lang.rightbar.secs[lang]}}</li>
							<template v-for="aplicats in indicadores" v-if="!aplicats.opcions">
								<li v-if="currentLista&&currentLista.includes(aplicats.codename)">{{textos_lang.indicadores[aplicats.key].name[lang]}}</li>
							</template>
							<template v-for="aplicats in indicadores" v-if="aplicats.opcions">
								<li v-for="(opcio, index) in aplicats.opcions" v-if="currentLista&&currentLista.includes(opcio.codename)" v-html="textos_lang.indicadores[aplicats.key].name[lang] + '<br>'+ textos_lang.indicadores[aplicats.key].opciones[index].subtitulo[lang]"></li>
							</template>
						</ul>
					</div>
				</template>
			</div>
		</div>
	</transition>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import Leyenda from './Leyenda.vue'
import { store } from '../store/store'
import numeral from 'numeral'

export default {

  	name: 'Rightbar',
  	components:{Leyenda},
  	computed: {
		...Vuex.mapGetters(['indicador_actual','currentApp','currentVista','currentLista', 'currentFiltros']),
		...Vuex.mapState(['filtros','indicadores','lista_filtros', 'rightSidebar','opcioSeleccionada','vista','textos_lang','lang']),
		
	},
	methods: {
		formatear(value){
			numeral.locale('es');
			return numeral(value).format(0.);
		}
	},
	
}
</script>

<style lang="scss" >

</style>