<template>
	<div class="menu-escenaris">
		<label  v-for="escenari in escenarisActual">
			<input type="radio" name="selescenari" :value="escenari.key" v-model="escenariSeleccionado" @change="updateEscenari()">
			<span>{{textos_lang.escenarios[escenari.key][lang]}}</span>
			<div class="radio-input"></div>
		</label>
		<h4 class="subtitle">{{textos_lang.escenari.post[lang]}}</h4>
		<label  v-for="escenari in escenarisPost" v-if="currentApp.escenarios.includes(escenari.key)">
			<input type="radio" name="selescenari" :value="currentApp.escenarios.indexOf(escenari.key)" v-model="escenariSeleccionado" @change="updateEscenari()">
			<span>{{currentApp.escenarios.indexOf(escenari.key)}} - {{textos_lang.escenarios[escenari.key][lang]}}</span>
			<div class="radio-input"></div>
		</label>
		<h4 class="subtitle" v-if="escenarisComp.length>0">{{textos_lang.escenari.comp[lang]}}</h4>
    <label  v-for="escenari in escenarisComp">
			<input type="radio" name="selescenari" :value="currentApp.escenarios.indexOf(escenari.key)" v-model="escenariSeleccionado" @change="updateEscenari()">
			<span>{{textos_lang.escenarios[escenari.key][lang]}}</span>
			<div class="radio-input"></div>
		</label>
	</div>
</template>

<script>
import Vuex from 'vuex'
import  { store } from '../store/store'
export default {

  name: 'Escenari',
  computed: {
  	...Vuex.mapState(['escenariSeleccionado','vista','textos_lang','lang']),
  	...Vuex.mapGetters(['escenarisActual','escenarisPost','escenarisComp','currentVista','currentApp']),
  	escenariSeleccionado: {
			get() {
				return store.state.escenariSeleccionado
			},
			set(value) {
				store.commit('change_escenari', value)
			}
	  }
  },
  created(){
   
  },
  methods: {
  	updateEscenari(){
  		const map = this.$parent.$parent.$refs.mapa.$children[0].map;
  		const escenari = store.state.escenariSeleccionado;
  		var valors = []
  		const datatipus = 'cat';
  		const reset = [];
      var app = store.getters.currentApp;
      if(document.querySelector('.tabla-section').style.transform=='translateY(0%)'){
        store.commit('get_dataTabla')  
      }
      
      store.state.capaUzeb.source.url = `${app.url}_${escenari}.json`;
      var _this = this;
      new Promise(resolve=> {
        map.removeLayer('uzeb');
        map.removeSource('uzeb');
        resolve()
      }).then( function(){
        map.addLayer(store.state.capaUzeb, store.state.textos);
        store.commit('change_estiloIndicador', map);
        map.setPaintProperty('uzeb','fill-color',store.state.estiloIndicador);
        
        if(!store.state.vista){
          map.setFilter('uzeb', store.state.filtros)
          store.commit('change_estiloIndicador', map)
          map.setPaintProperty('uzeb', 'fill-color', store.state.estiloIndicador)  
          var key = 'general'
          store.dispatch('updateAmbit', {key})
        } else {
          map.setFilter('uzeb', _this.currentVista.filtro);
          store.commit('change_estiloIndicador', map)
          map.setPaintProperty('uzeb', 'fill-color', store.state.estiloIndicador);
          var key = store.state.vista
          store.dispatch('updateAmbit', {key})
        }
      })
  	}
  }
  
}
</script>

<style lang="scss" >

</style>