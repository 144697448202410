
<template>
	<div class="vistamapa">
		  <Mapa ref="mapa" v-if="((currentApp.tipo=='pro'||currentApp.tipo=='show'||currentApp.tipo=='arquitectonico')&&user)||(currentApp.tipo=='simple')||(currentApp.tipo=='open')"/>
	     <Botones/>
	     <Leftbar/>
	     <Tabla/>
	     <Botonesright/>
	     <Topright/>
	     <Rightbar />
  	</div>
</template>

<script>
  import Mapa from './Mapa'
  import Botones from './Botones'
  import Leftbar from './Leftbar'
  import Rightbar from './Rightbar'
  import Botonesright from './Botonesright'
  import Topright from './Topright'
  import Tabla from './Tabla'
  import {store} from '../store/store'
  import Vuex from 'vuex'
export default {
  //este componente se ha creado para poder pasar de Mapa a estadísticas desde la misma página. con router
  name: 'VistaMapa',
  components:{Mapa, Botones, Leftbar, Rightbar, Botonesright, Topright, Tabla},
  data () {
    return {

    }
  },
  computed:{
      ...Vuex.mapState(['user','loading']),
      ...Vuex.mapGetters(['currentApp']),
    },
  mounted(){
   
  }
}
</script>

<style lang="css" scoped>
</style>