<template>
	<div class="seleccions-seccio">
		
		<div class="radio-sel">
			<label>
				<input type="radio" name="selambit" value="general" @change="updateAmbit($event.target.value)" checked>
				<span>{{textos_lang.vista.gen[lang]}}</span>
				<div class="radio-input"></div>
			</label>
			<h4 class="subtitle">{{textos_lang.vista.pers[lang]}}</h4>
			<template v-if="seleccions.length==0">
				<label class="nosel">{{textos_lang.vista.nopers[lang]}}</label>
			</template>
			<template v-else>
				<div  class="lista-sel" v-for="sel in orderSeleccions">
  				<label>
            <input type="radio" name="selambit" :value="sel.id_sel" @change="updateAmbit($event.target.value)">
  					<span>{{sel.nom_sel}}</span>
					  <div class="radio-input"></div>
          </label>
					<div class="desc" v-if="sel.desc_sel"><span>{{textos_lang.vista.desc[lang]}}:</span> {{sel.desc_sel}}</div>
          <div class="desc"><span>{{textos_lang.vista.numero[lang]}}:</span> {{sel.num_features | numeralFormat}}</div>
          <div class="botonesel">
            <button v-if="sel.nom_sel!='Projecte pilot Rubí'" class="boton sub-boton" :class="'info'+sel.id_sel" v-on:click="modalambit('verdefinicio',sel.id_sel)">
              <font-awesome-icon icon="info" />
            </button>
						<button v-if="sel.nom_sel!='Projecte pilot Rubí'" class="boton sub-boton" v-on:click="modalambit('editar',sel.id_sel)">
							<font-awesome-icon icon="pen" />
						</button>
						<button v-if="sel.nom_sel!='Projecte pilot Rubí'" class="boton sub-boton" @click="modalambit('duplicar',sel.id_sel)">
							<font-awesome-icon icon="clone" />
						</button>
						<button v-if="sel.nom_sel!='Projecte pilot Rubí'" class="boton sub-boton" @click="modalambit('eliminar',sel.id_sel)">
							<font-awesome-icon icon="trash-alt" />
						</button>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import {store} from '../store/store.js'
import Vuex from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import Vueaxios from 'vue-axios'
import Modalambit from './Modalambit.vue'
import VueNumerals from 'vue-numerals';
Vue.use(VueNumerals, {
  locale: 'es'
});
export default {

  name: 'Seleccions',

  data () {
    return {
    	modal:false,
    	editar:false,
    	duplicar:false,
    	eliminar:false,
    	seleccio:'',
    	alerta:false,
      verdefinicio:false,
      tab:1,
    }
  },
  computed:{
  	...Vuex.mapState(['seleccions','indicadors','textos_lang','lang']),
    ...Vuex.mapGetters(['orderSeleccions', 'currentApp']),

  },
  methods:{
    updateAmbit(key){
      store.dispatch('updateAmbit', {key})
    },
    modalambit(opcio,key){
      this.seleccio = this.seleccions.find(sel=> sel.id_sel==key)
      var modalcontainer = document.createElement('div')
      modalcontainer.setAttribute('id','modalambit')
      document.querySelector('#app').appendChild(modalcontainer)
      new Modalambit({
                store,
                propsData: { opcio: opcio,seleccio:this.seleccio},
              }).$mount('#modalambit')
    },
  },	
  mounted: function(){
  
  }
  
}
</script>

<style lang="css" >
</style>