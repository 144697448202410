import Vue from 'vue'
import Router from 'vue-router'
import VistaMapa from '@/components/VistaMapa'
import Estadistiques from '@/components/Estadistiques'
import App from '../App.vue'

Vue.use(Router)

export default new Router({
  mode:'history',
  //base:'/',
  routes: [
    {
      path: '/:lang',
      component: App,
    },
    /*{
      path: '',
      name: 'VistaMapa',
      component: VistaMapa
    },
    {
      path: '/estad',
      name: 'Estadistiques',
      component: Estadistiques
    }*/
  ]
})
