<template>
	<transition name="fadein">
			<div class="modal-mask login">
      			<div class="modal-wrapper">
        			<div class="modal-container">
						<div class="emailform">
		 	
						 	<div class="formcont">
						 		<img src="../assets/urbanzeb.svg" alt="logo" class="brand" data-src="../assets/urbanzeb.svg" data-src-retina="../assets/urbanzeb.svg" width="350" >
						 		<p class="info">{{textos_lang.login.bienv[lang]}} <span v-html="currentApp.titulo"></span>.<br> {{textos_lang.login.datos[lang]}}</p>
								<div class="formulario">
									<form id="login">
										<div class="alerta" v-if="alerta" v-html="alerta"></div>
										<div v-html="form"></div>
										<button type="submit" form="login" @click.prevent="login()"><span>{{textos_lang.login.enviar[lang]}}</span> <div class="boton"><font-awesome-icon icon="paper-plane" /></div></button>
									</form>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	</transition>
</template>

<script>
import Vuex from 'vuex'
import axios from 'axios'
import Vueaxios from 'vue-axios'
import  {store} from '../store/store'
export default {

  name: 'Login',

  data () {
    return {
    	form:'',
    	alerta:false,
    }
  },
  computed: {
  	...Vuex.mapState(['user','textos_lang','lang']),
  	...Vuex.mapGetters(['currentApp'])
  },
  created(){
  	axios.post(`api/get_authform/`)
			.then(response => {
				this.form = response.data.form
			})
			.catch(err => {console.log(err);});
  },
  methods: {
  	getCookie(name) {
	  var value = "; " + document.cookie;
	  var parts = value.split("; " + name + "=");
	  if (parts.length == 2) return parts.pop().split(";").shift();
	},
  	login(){
  		var data = new FormData(document.querySelector('#login'))
  		var csrftoken = this.getCookie('csrftoken')
  		axios.post(`api/validate_login/`, data, {headers:{'X-CSRFToken':csrftoken}})
			.then(response => {
				if(response.data.status=='Ok'){
					store.state.user = response.data.user;
					store.state.seleccions = response.data.custom_selections;
					//si estamos en la App de Rubí, creamos la selección
			        if(store.getters.currentApp.key == 6 && response.data.custom_selections.length==0){
			          var data = {
			            'nom_sel': 'Projecte pilot Rubí',
			            'desc_sel': '',
			            'filtro': ["all",["any",["all",["in","ep","SUISSA23","LORCA37","NOVA1","RIBAS27_A","MOLINS43","FATIMA45","GAIETA17","MUMANY16","ALBA25"]]]],
			            'definicio': [],
			          }
			          var csrftoken = store.dispatch('getCookie', 'csrftoken')
			          axios.post(`api/create_selection/`, data, {headers:{'X-CSRFToken':csrftoken}})
			          .then(response => {
			            if(response.data.seleccion_object){
			            store.state.seleccions.push(response.data.seleccion_object);
			            store.state.loading = false;
			            } else {
			              store.state.errorloading = true;
			              setTimeout(function() {
			                store.state.loading = false;
			                store.state.errorloading = false
			                   
			              }, 3000)
			            }
			          })
			        }
					this.data = false
				} else if (response.data.status=='Fail'){
					this.alerta = response.data.message
				}
				
			})
			.catch(err => {console.log(err);});
  	}
  }
}
</script>

<style lang="css" scoped>
</style>