<template>
  <div class="botonesleft" :class="[{leftopen: leftSidebar}, currentApp.tipo]">
    <button 
      v-for="boton in botonesleft" 
      class="boton" 
      @click="swapComponent(boton.apartado,boton.key)" 
      :ref="boton.apartado" 
      v-if="boton.apps.includes(currentApp.tipo)">
        <font-awesome-icon :icon="boton.icono" />
        <transition name="fadein">
          <span 
            class="tooltip" 
            v-if="!$isMobile()" 
            v-html="textos_lang.botonesleft[boton.key][lang]" >
          </span>
        </transition>
    </button>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'  
export default {

  name: 'Botones',
  computed: {
    ...Vuex.mapState(['botonesleft','currentcomponent','leftSidebar','rightSidebar','textos_lang','lang']),
    ...Vuex.mapGetters(['indicador_actual','currentApp']),
  },
  methods: {
    ...Vuex.mapMutations(['change_component','change_tituloseccio','toggle_Sidebar']),
    swapComponent(apartado,alt) {
      //guardamos el nuevo componente actual del sidebar de la izq en el state
      this.change_component(apartado);
      //guardamos y cambiamos su título para mostrarlo en el sidebar
      this.change_tituloseccio(alt);
      //si la barra lateral izqda no está abierta
      if(this.leftSidebar==false){
        //si la pantalla es pequeña no pueden estar las dos barras laterales abiertas al mismo tiempo
        if(window.innerWidth<=600&&this.rightSidebar&&this.currentApp.tipo!='open'){
              this.toggle_Sidebar('rightSidebar');
              document.querySelector('.mapboxgl-ctrl-bottom-right').classList.add('rightclose');
         }
         //abrimos la barra izquierda y movemos los botones de la izqda del mapa
        this.toggle_Sidebar('leftSidebar');
        if(this.currentApp.tipo!='open'){
          document.querySelector('.mapboxgl-ctrl-bottom-left').style.left='250px';  
        }
        
      }
      //pintamos el botón seleccionado de blanco y el resto de azul
      var botones = document.getElementsByClassName('botonesleft')[0].children;
      for(var b=0; b<botones.length; b++){
        botones[b].style.background='#4197af';
      }
      this.$refs[apartado][0].style.background= '#fff';
      //si el apartado al que vamos no es Filtres, escondemos la eina de Dibuix y reactivamos los tooltips
      if(apartado!='Filtres'){
        var elem = document.querySelector('.einaDibuix')
        const map = this.$parent.$refs.mapa.$children[0].map;
        var layerseccions = map.getLayer('seccionsfill');
        if(elem&&elem.style.display!='none'){
          document.querySelector('.dibuix .titlesel button').click();
        }
        if(typeof layerseccions !== 'undefined'){
          document.querySelector('.seccions .titlesel button').click();
        }
        var tooltips = document.querySelectorAll('.tooltip')
        tooltips.forEach(tip => tip.style.display = 'block')
      }
    }
  },
}
</script>

<style lang="scss">

</style>