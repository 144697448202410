var tit = {
    var : {ca:' Variació respecte a l\'escenari actual', es: ' Variación respecto al escenario actual'},
    cte : {ca:' Llindar confort', es: ' Umbral confort'},
    sal : {ca:' Llindar salut', es: ' Umbral salud'},
    hip: {ca:'Hipòtesi', es:'Hipótesis'},
    ele : {ca:' Vector energètic electricitat', es: ' Vector energético electricidad'},
    gas : {ca:' Vector energètic gas', es: ' Vector energético gas'},
    tot : {ca:' Per secció', es: ' Por sección'},
    pi : {ca:' Per immoble', es:' Por inmueble'},
    ph : {ca:' Per habitatge', es:' Por vivienda'},
    pm : {ca:' Per m<sup>2</sup>', es:' Por m<sup>2</sup>'},
    mi : {ca:' Moda immobles', es:' Moda inmuebles'},
    mh : {ca:' Moda habitatges', es:' Moda viviendas'},
    sr : {ca:' Sobre rasant', es:' Sobre rasante'},
    ss : {ca:' Sota rasant', es:' Bajo rasante'},
    si : {ca:' Indeterminada', es:' Indeterminada'},
    teo: {ca:'Consum teòric', es:'Consumo teórico'},
    real: {ca:'Consum real', es:'Consumo real'},
    ui: {ca:'imm', es:'inm'},
    uh: {ca:'hab', es:'viv'},
    um: {ca:'m<sup>2</sup>', es:'m<sup>2</sup>'},
    total: {ca:'Total',es:'Total'},
    comp: {ca:'Comparativa',es:'Comparativa'}
}
export var textos_lang = {
	indicadores:[
		{
			key:0,
			name:{
				ca:'Ús principal',
				es:'Uso principal'
			},
			valores:[
				{
					ca:'Residencial',
					es:'Residencial'
				},
				{
					ca:'Ensenyament / Cultural',
					es:'Enseñanza / Cultural'
				},
				{
					ca:'Hoteler / Restauració',
					es:'Hostelería / Restauración'
				},
				{
					ca:'Esportiu',
					es:'Deportivo'
				},
				{
					ca:'Piscines',
					es:'Piscinas'
				},
				{
					ca:'Comercial',
					es:'Comercial'
				},
				
				{
					ca:'Oficines',
					es:'Oficinas'
				},
				{
					ca:'Aparcament',
					es:'Aparcamiento'
				},
				{
					ca:'Emmagatzematge',
					es:'Almacén'
				},
				
				
				{
					ca:'Industrial',
					es:'Industrial'
				},			
				
				
				
				{
					ca:'Altres',
					es:'Otros'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
			],
			fuente:{
				ca:'Catastro de España Febrero 2019',
				es:'Catastro de España Febrero 2019'
			} 
		},
		{
			key:1,
			name:{
				ca:'Tipus d\'immoble residencial',
				es:'Tipo de inmueble residencial'
			},
			valores:[
				{
					ca:'Exclusivament Residencial',
					es:'Exclusivamente Residencial'
				},
				{
					ca:'Més de 75% Residencial',
					es:'Más de 75% Residencial'
				},
				{
					ca:'Entre 50% i 75% Residencial',
					es:'Entre 50% i 75% Residencial'
				},
				{
					ca:'Menys de 50% Residencial',
					es:'Menos de 50% Residencial'
				},
				{
					ca:'No residencial',
					es:'No residencial'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
			]
		},
		{
			key:2,
			name: {
				ca:'Tipus de propietat residencial',
				es:'Tipo de propiedad residencial'
			},
			valores: [
				{
					ca: 'Plurifamiliar',
					es: 'Plurifamiliar'
				},
				{
					ca: 'Unifamiliar',
					es: 'Unifamiliar'
				},
				{
					ca: 'Sense dades / No residencial',
					es: 'Sin datos / No residencial'
				},
			]
		},
		{
			key:3,
			name:{
				ca:'Any de construcció',
				es:'Año de construcción'
			},
			unitat: {
				ca:'',
				es:''
			},
			valores: [
				{
					ca:'De 2008 en endavant',
					es:'De 2008 en adelante'
				},
				{
					ca:'De 1981 a 2007',
					es:'De 1981 a 2007'
				},
				{
					ca:'De 1961 a 1980',
					es:'De 1961 a 1980'
				},
				{
					ca:'De 1941 a 1960',
					es:'De 1941 a 1960'
				},
				{
					ca:'De 1901 a 1940',
					es:'De 1901 a 1940'
				},
				{
					ca:'Fins a 1900',
					es:'Hasta 1900'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
			],
			opciones:[
				{key:0,subtitulo: {ca:'Categoritzat',es:'Categorizado'}},
				{key:1,subtitulo: {ca:'Absolut',es:'Absoluto'}}
			],
			fuente:{
				ca:'Catastro de España Febrero 2019',
				es:'Catastro de España Febrero 2019'
			} 
		},
		{
			key:4,
			name:{
				ca:'Nombre d\'habitatges',
				es:'Número de viviendas'
			},
			valores:[
				{
					ca:'40 o més habitatges',
					es:'40 o más viviendas'
				},
				{
					ca:'De 20 a 39 habitatges',
					es:'De 20 a 39 viviendas'
				},
				{
					ca:'De 10 a 19 habitatges',
					es:'De 10 a 19 viviendas'
				},
				{
					ca:'De 5 a 9 habitatges',
					es:'De 5 a 9 viviendas'
				},
				{
					ca:'De 2 a 4 habitatges',
					es:'De 2 a 4 viviendas'
				},
				{
					ca:'Plurifamiliar Corregit',
					es:'Plurifamiliar Corregido'
				},
				{
					ca:'Unifamiliar',
					es:'Unifamiliar'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
			],
			opciones:[
				{key:0,subtitulo: {ca:'Categoritzat',es:'Categorizado'}},
				{key:1,subtitulo: {ca:'Absolut',es:'Absoluto'}}
			],
			fuente:{
				ca:'Catastro de España Febrero 2019',
				es:'Catastro de España Febrero 2019'
			},
			unitat:{
				ca:'habitatges',
				es:'viviendas'
			}
		},
		{
			key:5,
			name:{
				ca:'Nombre de plantes',
				es:'Número de plantas'
			},
			valores: [
				{
					ca:'Planta Baixa +3 o més',
					es:'Planta Baja +3 o más'
				},
				{
					ca:'De Planta Baixa a PB+2',
					es:'De Planta Baja a PB+2'
				},
				{
					ca:'De P-1 en avall',
					es:'De P-1 hacia abajo'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
			],
			opciones:[
				{key:0,subtitulo:{ca:'Categoritzat',es:'Categorizado'}},
				{key:1,subtitulo:{ca:'Absolut',es:'Absoluto'}}
			],
			fuente:{
				ca:'Catastro de España Febrero 2019',
				es:'Catastro de España Febrero 2019'
			},
			unitat: {
				ca:'plantes',
				es:'plantas'
			}
		},
		{
			key:6,
			name:{
				ca:'Tipus d\'ordenació urbanística',
				es:'Tipo de ordenación urbanística'
			},
			valores:[
				{
					ca:'Edificació aïllada',
					es:'Edificación aislada'
				},
				{
					ca:'Edificació segons alineació a vial',
					es:'Edificación según alineación a vial'
				},
				{
					ca:'Edificació en filera',
					es:'Edificación en hilera'
				},
				{
					ca:'Edificació amb volumetría específica',
					es:'Edificación con volumetría específica'
				},
				{
					ca:'Edificació indeterminada',
					es:'Edificación indeterminada'
				},
				{
					ca:'No residencial',
					es:'No residencial'
				},
				{
					ca:'Sense dades',
					es:'Sin datos'
				},
			]
		},
		{
			key:7,
			name:{
				ca:'Clúster',
				es:'Clúster'
			},
			valores: [
				{
					ca:'A',
					es:'A'
				},{
					ca:'B',
					es:'B'
				},{
					ca:'C',
					es:'C'
				},{
					ca:'D',
					es:'D'
				},{
					ca:'E',
					es:'E'
				},{
					ca:'F',
					es:'F'
				},{
					ca:'G',
					es:'G'
				},{
					ca:'H',
					es:'H'
				},{
					ca:'I',
					es:'I'
				},{
					ca:'J',
					es:'J'
				},{
					ca:'K',
					es:'K'
				},{
					ca:'L',
					es:'L'
				},{
					ca:'Sense dades',
					es:'Sin datos'
				},
			]
		},
		{
			key:8,
			name:{
				ca:'Superfície construïda',
				es:'Superficie construida'
			},
			opciones: [
				{
					key:0,
					subtitulo:{
						ca: tit.sr.ca,
						es: tit.sr.es
					}
				},
				{
					key:1,
					subtitulo:{
						ca: tit.ss.ca,
						es: tit.ss.es
					}
				},
				{
					key:2,
					subtitulo:{
						ca: tit.si.ca,
						es: tit.si.es
					}
				}
			],
			unitat:{
				ca:'m<sup>2</sup>',
				es:'m<sup>2</sup>'
			}
		},
		{
			key:9,
			name:{
				ca:'Superfície residencial construïda',
				es:'Superficie residencial construida'
			},
			unitat:{
				ca:'m<sup>2</sup>',
				es:'m<sup>2</sup>'
			}
		},
		{
			key:10,
			name:{
				ca:'Temperatura interior',
				es:'Temperatura interior'
			},
			unitat:{
				ca:'graus',
				es:'grados'
			}
		},
		{
			key:11,
			name:{
				ca:'Autonomía tèrmica',
				es:'Autonomía térmica'
			},
			unitat:{
				ca:'hores',
				es:'horas'
			}

		},
		{
			key:12,
			name: {
				ca:'Salt tèrmic',
				es:'Salto térmico'
			},
			unitat:{
				ca:'graus·dia',
				es:'grados·día'
			}
		},
		{
			key:13,
			name:{
				ca:'Demanda de calefacció',
				es:'Demanda de calefacción'
			},
			valores:[
				{
					ca:'135.000 o més kWh/immoble·any',
					es:'135.000 o más kWh/vivienda·año'
				},
				{
					ca:'Entre 120.000 i 134.999 kWh/imm·any',
					es:'Entre 120.000 y 134.999 kWh/viv·año'
				},
				{
					ca:'Entre 105.000 i 119.999 kWh/imm·any',
					es:'Entre 105.000 y 119.999 kWh/viv·año'
				},
				{
					ca:'Entre 90.000 i 104.999 kWh/imm·any',
					es:'Entre 90.000 y 104.999 kWh/viv·año'
				},
				{
					ca:'Entre 75.000 i 89.999 kWh/imm·any',
					es:'Entre 75.000 y 89.999 kWh/viv·año'
				},
				{
					ca:'Entre 60.000 i 74.999 kWh/imm·any',
					es:'Entre 60.000 y 74.999 kWh/viv·año'
				},
				{
					ca:'Entre 45.000 i 59.999 kWh/imm·any',
					es:'Entre 45.000 y 59.999 kWh/viv·año'
				},
				{
					ca:'Entre 30.000 i 44.999 kWh/imm·any',
					es:'Entre 30.000 y 44.999 kWh/viv·año'
				},
				{
					ca:'Entre 15.000 i 29.999 kWh/imm·any',
					es:'Entre 15.000 y 29.999 kWh/viv·año'
				},
				{
					ca:'Menys de 15.000 kWh/immoble·any',
					es:'Menos de 15.000 kWh/vivienda·año'
				},
				{
					ca:'Sense dades',
					es:'Sin datos'
				},
			],
			opciones:[
				{
					key:0,
					subtitulo: {ca: tit.pi.ca + '<br>' + tit.cte.ca, es: tit.pi.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.cte.ca + '<br>' + tit.var.ca, es: tit.cte.es + '<br>' + tit.cte.es}
				},
				{
					key:1,
					subtitulo: {ca: tit.ph.ca + '<br>' + tit.cte.ca, es: tit.ph.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.cte.ca + '<br>' + tit.var.ca, es: tit.cte.es + '<br>' + tit.cte.es}
				},
				{
					key:2,
					subtitulo: {ca: tit.pm.ca + '<br>' + tit.cte.ca, es: tit.pm.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.cte.ca + '<br>' + tit.var.ca, es: tit.cte.es + '<br>' + tit.cte.es}
				},
				{
					key:3,
					subtitulo: {ca: tit.pi.ca + '<br>' + tit.sal.ca, es: tit.pi.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.sal.ca + '<br>' + tit.var.ca, es: tit.sal.es + '<br>' + tit.sal.es}
				},
				{
					key:4,
					subtitulo: {ca: tit.ph.ca + '<br>' + tit.sal.ca, es: tit.ph.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.sal.ca + '<br>' + tit.var.ca, es: tit.sal.es + '<br>' + tit.sal.es}
				},
				{
					key:5,
					subtitulo: {ca: tit.pm.ca + '<br>' + tit.cte.ca, es: tit.pm.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.sal.ca + '<br>' + tit.var.ca, es: tit.sal.es + '<br>' + tit.sal.es}
				},
				{
					key:6,
					subtitulo: {ca: tit.pi.ca + '<br>' + tit.cte.ca, es: tit.pi.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.cte.ca + '<br>' + tit.var.ca, es: tit.cte.es + '<br>' + tit.cte.es}
				},
				{
					key:7,
					subtitulo: {ca: tit.ph.ca + '<br>' + tit.cte.ca, es: tit.ph.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.cte.ca + '<br>' + tit.var.ca, es: tit.cte.es + '<br>' + tit.cte.es}
				},
				{
					key:8,
					subtitulo: {ca: tit.pm.ca + '<br>' + tit.cte.ca, es: tit.pm.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.cte.ca + '<br>' + tit.var.ca, es: tit.cte.es + '<br>' + tit.cte.es}
				},
				{
					key:9,
					subtitulo: {ca: tit.pi.ca + '<br>' + tit.sal.ca, es: tit.pi.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.sal.ca + '<br>' + tit.var.ca, es: tit.sal.es + '<br>' + tit.sal.es}
				},
				{
					key:10,
					subtitulo: {ca: tit.ph.ca + '<br>' + tit.sal.ca, es: tit.ph.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.sal.ca + '<br>' + tit.var.ca, es: tit.sal.es + '<br>' + tit.sal.es}
				},
				{
					key:11,
					subtitulo: {ca: tit.pm.ca + '<br>' + tit.cte.ca, es: tit.pm.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.sal.ca + '<br>' + tit.var.ca, es: tit.sal.es + '<br>' + tit.sal.es}
				},
			],
			unitat: {
				ca: 'kWh·any',
				es: 'kWh·año'
			}
		},
		{
			key:14,
			name:{
				ca:'Qualificació energética: Demanda de calefacció',
				es:'Calificación energética: Demanda de calefacción'
			},
			valores:[
				{
					ca:'A',
					es:'A'
				},
				{
					ca:'B',
					es:'B'
				},
				{
					ca:'C',
					es:'C'
				},
				{
					ca:'D',
					es:'D'
				},
				{
					ca:'E',
					es:'E'
				},
				{
					ca:'F',
					es:'F'
				},
				{
					ca:'G',
					es:'G'
				},
				{
					ca:'Sense dades',
					es:'Sin datos'
				},
			]
		},
		{
			key:15,
			name:{
				ca:'Consum de calefacció',
				es:'Consume de calefacción'
			},
			opciones: [
				{
					key:0,
					subtitulo: {ca: tit.pi.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca, es: tit.pi.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca, es: tit.ele.es + '<br>' + tit.cte.es + '<br>' + tit.var.es}
				},
				{
					key:1,
					subtitulo: {ca: tit.ph.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca, es: tit.ph.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca, es: tit.ele.es + '<br>' + tit.cte.es + '<br>' + tit.var.es}
				},
				{
					key:2,
					subtitulo: {ca: tit.pm.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca, es: tit.pm.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca, es: tit.ele.es + '<br>' + tit.cte.es + '<br>' + tit.var.es}
				},
				{
					key:3,
					subtitulo: {ca: tit.pi.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca, es: tit.pi.es + '<br>' + tit.ele.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca, es: tit.ele.es + '<br>' + tit.sal.es + '<br>' + tit.var.es}
				},
				{
					key:4,
					subtitulo: {ca: tit.ph.ca + '<br>' + tit.ele.ca + '<br>' + tit.sal.ca, es: tit.ph.es + '<br>' + tit.ele.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca, es: tit.ele.es + '<br>' + tit.sal.es + '<br>' + tit.var.es}
				},
				{
					key:5,
					subtitulo: {ca: tit.pm.ca + '<br>' + tit.ele.ca + '<br>' + tit.sal.ca, es: tit.pm.es + '<br>' + tit.ele.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca, es: tit.ele.es + '<br>' + tit.sal.es + '<br>' + tit.var.es}
				},
				{
					key:6,
					subtitulo: {ca: tit.pi.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca, es: tit.pi.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca, es: tit.gas.es + '<br>' + tit.cte.es + '<br>' + tit.var.es}
				},
				{
					key:7,
					subtitulo: {ca: tit.ph.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca, es: tit.ph.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca, es: tit.gas.es + '<br>' + tit.cte.es + '<br>' + tit.var.es}
				},
				{
					key:8,
					subtitulo: {ca: tit.pm.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca, es: tit.pm.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es},
					titulovar: {ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca, es: tit.gas.es + '<br>' + tit.cte.es + '<br>' + tit.var.es}
				},
				{
					key:9,
					subtitulo: {ca: tit.pi.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca, es: tit.pi.es + '<br>' + tit.gas.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca, es: tit.gas.es + '<br>' + tit.sal.es + '<br>' + tit.var.es}
				},
				{
					key:10,
					subtitulo: {ca: tit.ph.ca + '<br>' + tit.gas.ca + '<br>' + tit.sal.ca, es: tit.ph.es + '<br>' + tit.gas.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca, es: tit.gas.es + '<br>' + tit.sal.es + '<br>' + tit.var.es}
				},
				{
					key:11,
					subtitulo: {ca: tit.pm.ca + '<br>' + tit.gas.ca + '<br>' + tit.sal.ca, es: tit.pm.es + '<br>' + tit.gas.es + '<br>' + tit.sal.es},
					titulovar: {ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca, es: tit.gas.es + '<br>' + tit.sal.es + '<br>' + tit.var.es}
				},

			],
			unidad: {
				ca:'kWh',
				es:'kWh'
			}
		},
		{
			key:16,
			name:{
				ca:'Qualificació energètica: Consum de calefacció',
				es:'Calificación energética: Consumo de calefacción'
			},
			valores:[
				{
					ca:'A',
					es:'A'
				},
				{
					ca:'B',
					es:'B'
				},
				{
					ca:'C',
					es:'C'
				},
				{
					ca:'D',
					es:'D'
				},
				{
					ca:'E',
					es:'E'
				},
				{
					ca:'F',
					es:'F'
				},
				{
					ca:'G',
					es:'G'
				},
				{
					ca:'Sense dades',
					es:'Sin datos'
				},
			],
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca ,
						es: tit.ele.es,
					}
				},
				{
					key:1,
					subtitulo: {
						ca: tit.gas.ca ,
						es: tit.gas.es,
					}
				},
				
			]
		},
		{
			key:17,
			name:{
				ca:'Consum total',
				es:'Consumo total'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.pi.es + '<br>' + tit.ele.es + '<br>' + tit.ele.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.cte.es + '<br>' + tit.var.es
					}
				},
				{
					key:1,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.ph.es + '<br>' + tit.ele.es + '<br>' + tit.ele.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.cte.es + '<br>' + tit.var.es
					}
				},
				{
					key:2,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.pm.es + '<br>' + tit.ele.es + '<br>' + tit.ele.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.cte.es + '<br>' + tit.var.es
					}
				},
				{
					key:3,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.ele.ca + '<br>' + tit.sal.ca,
						es: tit.pi.es + '<br>' + tit.ele.es + '<br>' + tit.ele.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.sal.es + '<br>' + tit.var.es
					}
				},
				{
					key:4,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.ele.ca + '<br>' + tit.sal.ca,
						es: tit.ph.es + '<br>' + tit.ele.es + '<br>' + tit.ele.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.sal.es + '<br>' + tit.var.es
					}
				},
				{
					key:5,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.ele.ca + '<br>' + tit.sal.ca,
						es: tit.pm.es + '<br>' + tit.ele.es + '<br>' + tit.ele.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.sal.es + '<br>' + tit.var.es
					}
				},
				{
					key:6,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.pi.es + '<br>' + tit.gas.es + '<br>' + tit.gas.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.cte.es + '<br>' + tit.var.es
					}
				},
				{
					key:7,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.ph.es + '<br>' + tit.gas.es + '<br>' + tit.gas.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.cte.es + '<br>' + tit.var.es
					}
				},
				{
					key:8,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.pm.es + '<br>' + tit.gas.es + '<br>' + tit.gas.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.cte.es + '<br>' + tit.var.es
					}
				},
				{
					key:9,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.gas.ca + '<br>' + tit.sal.ca,
						es: tit.pi.es + '<br>' + tit.gas.es + '<br>' + tit.gas.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.sal.es + '<br>' + tit.var.es
					}
				},
				{
					key:10,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.gas.ca + '<br>' + tit.sal.ca,
						es: tit.ph.es + '<br>' + tit.gas.es + '<br>' + tit.gas.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.sal.es + '<br>' + tit.var.es
					}
				},
				{
					key:11,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.gas.ca + '<br>' + tit.sal.ca,
						es: tit.pm.es + '<br>' + tit.gas.es + '<br>' + tit.gas.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.sal.es + '<br>' + tit.var.es
					}
				},
			],
			unidad: {
				ca:'kWh',
				es:'kWh'
			}
		},
		{
			key:18,
			name:{
				ca:'Cost econòmic de la calefacció',
				es:'Coste económico de la calefacción'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.pi.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:1,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.ph.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:2,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.pm.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:3,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.pi.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:4,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.ele.ca + '<br>' + tit.sal.ca,
						es: tit.ph.es + '<br>' + tit.ele.es + '<br>' + tit.sal.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:5,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.ele.ca + '<br>' + tit.sal.ca,
						es: tit.pm.es + '<br>' + tit.ele.es + '<br>' + tit.sal.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:6,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.pi.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
				{
					key:7,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.ph.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
				{
					key:8,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.pm.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
				{
					key:9,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.pi.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
				{
					key:10,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.gas.ca + '<br>' + tit.sal.ca,
						es: tit.ph.es + '<br>' + tit.gas.es + '<br>' + tit.sal.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
				{
					key:11,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.gas.ca + '<br>' + tit.sal.ca,
						es: tit.pm.es + '<br>' + tit.gas.es + '<br>' + tit.sal.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
			],
			unidad: {
				ca:'€',
				es:'€'
			}
		},
		{
			key:19,
			name: {
				ca:'Import de la factura energètica',
				es:'Importe de la factura energética'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.pi.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:1,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.ph.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:2,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.pm.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:3,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.pi.es + '<br>' + tit.ele.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:4,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.ele.ca + '<br>' + tit.sal.ca,
						es: tit.ph.es + '<br>' + tit.ele.es + '<br>' + tit.sal.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:5,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.ele.ca + '<br>' + tit.sal.ca,
						es: tit.pm.es + '<br>' + tit.ele.es + '<br>' + tit.sal.es
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:6,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.pi.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
				{
					key:7,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.ph.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
				{
					key:8,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.pm.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
				{
					key:9,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.pi.es + '<br>' + tit.gas.es + '<br>' + tit.cte.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
				{
					key:10,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.gas.ca + '<br>' + tit.sal.ca,
						es: tit.ph.es + '<br>' + tit.gas.es + '<br>' + tit.sal.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
				{
					key:11,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.gas.ca + '<br>' + tit.sal.ca,
						es: tit.pm.es + '<br>' + tit.gas.es + '<br>' + tit.sal.es
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.gas.es + '<br>' + tit.var.es
					}
				},
			],
			unidad: {
				ca:'€',
				es:'€'
			}
		},
		{
			key:20,
			name: {
				ca:'Renda mínima per evitar pobresa energètica',
				es:'Renta mínima para evitar la pobreza energética'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca,
						es: tit.ele.es + '<br>' + tit.cte.es 
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.cte.es + '<br>' + tit.var.es
					}
				},
				{
					key:1,
					subtitulo: {
						ca: tit.ele.ca + '<br>' + tit.sal.ca,
						es: tit.ele.es + '<br>' + tit.sal.es 
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.sal.es + '<br>' + tit.var.es
					}
				},
				{
					key:2,
					subtitulo: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.gas.es + '<br>' + tit.cte.es 
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.cte.es + '<br>' + tit.var.es
					}
				},
				{
					key:3,
					subtitulo: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca,
						es: tit.gas.es + '<br>' + tit.sal.es 
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.sal.es + '<br>' + tit.var.es
					}
				},
			],
			unitat: {
				ca:'€',
				es:'€'
			}
		},
		{
			key:21,
			name: {
				ca:'Qualificació energética a Menorca',
				es:'Calificación energética en Menorca'
			},
			valores:[
				{
					ca:'A',
					es:'A'
				},
				{
					ca:'B',
					es:'B'
				},
				{
					ca:'C',
					es:'C'
				},
				{
					ca:'D',
					es:'D'
				},
				{
					ca:'E',
					es:'E'
				},
				{
					ca:'F',
					es:'F'
				},
				{
					ca:'G',
					es:'G'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
			],
			subtitulo: {
				ca:'Dades simulades de 2019',
				es:'Datos simulados de 2019'
			},
			fuente:{
				ca:'Catastro de España Febrero 2019',
				es:'Catastro de España Febrero 2019'
			} 
		},
		{
			key:22,
			name:{
				ca:'Superfície mitjana d\'habitatge per immoble a l\'AMB',
				es:'Superfici media de vivienda por inmueble en la AMB'
			},
			valores:[
				{
					ca:'Més de 250 m<sup>2</sup>',
					es:'Más de 250 m<sup>2</sup>'
				},
				{
					ca:'Entre 200 i 250 m<sup>2</sup>',
					es:'Entre 200 y 250 m<sup>2</sup>'
				},
				{
					ca:'Entre 150 i 200 m<sup>2</sup>',
					es:'Entre 150 y 200 m<sup>2</sup>'
				},
				{
					ca:'Entre 100 i 150 m<sup>2</sup>',
					es:'Entre 100 y 150 m<sup>2</sup>'
				},
				{
					ca:'Entre 80 i 100 m<sup>2</sup>',
					es:'Entre 80 y 100 m<sup>2</sup>'
				},
				{
					ca:'Entre 70 i 80 m<sup>2</sup>',
					es:'Entre 70 y 80 m<sup>2</sup>'
				},
				{
					ca:'Entre 60 i 70 m<sup>2</sup>',
					es:'Entre 60 y 70 m<sup>2</sup>'
				},
				{
					ca:'Entre 50 i 60 m<sup>2</sup>',
					es:'Entre 50 y 60 m<sup>2</sup>'
				},
				{
					ca:'Entre 40 i 50 m<sup>2</sup>',
					es:'Entre 40 y 50 m<sup>2</sup>'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
			],
			fuente:{
				ca:'Catastro de España Febrero 2019 / Servei Open Data BCN',
				es:'Catastro de España Febrero 2019 / Servicio Open Data BCN'
			} 
		},
		{
			key:23,
			name:{
				ca:'Quantitat de pisos turístics propers a l\'immoble a Barcelona',
				es:'Cantidad de pisos turísticos cercanos al inmueble en Barcelona'
			},
			subtitulo:{
				ca:'Habitatges d\'ús turístic en un radi de 50m al voltant de l\'immoble',
				es:'Viviendas de uso turístico en un radio de 50m alrededor del inmueble'
			},
			valores: [
				{
					ca:'Més de 50 pisos',
					es:'Más de 50 pisos'
				},
				{
					ca:'Entre 21 i 50 pisos',
					es:'Entre 21 y 50 pisos'
				},
				{
					ca:'Entre 6 i 20 pisos',
					es:'Entre 6 y 20 pisos'
				},
				{
					ca:'Entre 1 i 5 pisos',
					es:'Entre 1 y 5 pisos'
				},
				{
					ca:'Cap',
					es:'Ninguno'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
				
			],
			extra: {
				valores:[
					{
						ca:'Parcel·la amb pisos turístics',
						es:'Parcela con pisos turísticos'
					},
					{
						ca:'Parcel·la sense pisos turístics',
						es:'Parcela sin pisos turísticos'
					}
				]
			},
			fuente:{
				ca:'Catastro de España Febrero 2019 / Servei Open Data BCN',
				es:'Catastro de España Febrero 2019 / Servicio Open Data BCN'
			} 
		},
		{
			key:24,
			name:{
				ca:'Quantitat de comerços pròxims a l\'immbole residencial a S.Cugat del Vallès',
				es:'Cantidad de comercios próximos al inmueble residencial en S.Cugat del Vallès'
			},
			subtitulo:{
				ca:'Comerços d\'ús quotidià en un radi de 200m al voltant de l\'immoble',
				es:'Comercios de uso cotidiano en un radio de 200m alrededor del inmueble'
			},
			valores: [
				{
					ca:'Més de 50 comerços',
					es:'Más de 50 comercios'
				},
				{
					ca:'Entre 16 i 50 comerços',
					es:'Entre 16 y 50 comercios'
				},
				{
					ca:'Entre 4 i 15 comerços',
					es:'Entre 4 y 15 comercios'
				},
				{
					ca:'Entre 1 i 3 comerços',
					es:'Entre 1 y 3 comercios'
				},
				{
					ca:'Cap',
					es:'Ninguno'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
			],
			extra: {
				valores:[
					{
						ca:'Parcel·la amb comerç',
						es:'Parcela con comercio'
					},
					{
						ca:'Parcel·la sense comerç',
						es:'Parcela sin comercio'
					}
				]
			},
			fuente:{
				ca:'Catastro de España Febrero 2019',
				es:'Catastro de España Febrero 2019'
			} 
		},
		{
			key:25,
			name:{
				ca:'Compacitat',
				es:'Compacidad'
			},
			unitat: {
				ca:'m<sup>3</sup>/m<sup>2</sup>',
				es:'m<sup>3</sup>/m<sup>2</sup>'
			},
			
		},
		{
			key:26,
			name:{
				ca:'Uglobal',
				es:'Uglobal'
			},
			unitat: {
				ca:'W/m<sup>2</sup>·K',
				es: 'W/m<sup>2</sup>·K'
			}
		},
		{
			key:27,
			name:{
				ca:'Demanda de refrigeració',
				es:'Demanda de refrigeración'
			},
			unitat: {
				ca:'kWh',
				es:'kWh'
			},
			unitatTemps: {
				ca:'any',
				es:'año'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.pi.ca,
						es: tit.pi.es
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
				},
				{
					key:1,
					subtitulo: {
						ca: tit.ph.ca,
						es: tit.ph.es
					},
					uni: {ca: tit.uh.ca, es: tit.uh.es}
				},
				{
					key:2,
					subtitulo: {
						ca: tit.pm.ca,
						es: tit.pm.es
					},
					uni: {ca: tit.um.ca, es: tit.um.es}
				}
			]
		},
		{
			key:28,
			name:{
				ca:'Qualificació energética: Demanda de refrigeració',
				es:'Calificación energética: Demanda de refrigeración'
			},
			valores:[
				{
					ca:'A',
					es:'A'
				},
				{
					ca:'B',
					es:'B'
				},
				{
					ca:'C',
					es:'C'
				},
				{
					ca:'D',
					es:'D'
				},
				{
					ca:'E',
					es:'E'
				},
				{
					ca:'F',
					es:'F'
				},
				{
					ca:'G',
					es:'G'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
			],
			opciones: [
				{
					key:0,
					subtitulo:{
						ca: tit.teo.ca,
						es: tit.teo.es,
					}
				},
				{
					key:1,
					subtitulo:{
						ca: tit.real.ca,
						es: tit.real.es,
					}
				},
				
			]
		},
		{
			key:29,
			name:{
				ca:'Consum de calefacció',
				es:'Consumo de calefacción'
			},
			unitat: {
				ca:'kWh',
				es:'kWh'
			},
			unitatTemps: {
				ca:'any',
				es:'año'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.hip.ca + tit.ele.ca + '<br>' + tit.teo.ca ,
						es: tit.pi.es + '<br>' + tit.hip.es + tit.ele.es+ '<br>'+  tit.teo.es 
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
					
				},
				{
					key:1,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.hip.ca + tit.ele.ca + '<br>' + tit.teo.ca ,
						es: tit.ph.es + '<br>' + tit.hip.es + tit.ele.es + '<br>' + tit.teo.es,
					},
					uni: {ca: tit.uh.ca, es: tit.uh.es}
					
				},
				{
					key:2,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.hip.ca + tit.ele.ca + '<br>' + tit.teo.ca ,
						es: tit.pm.es + '<br>' + tit.hip.es + tit.ele.es + '<br>' + tit.teo.es,
					},
					uni: {ca: tit.um.ca, es: tit.um.es}
					
				},
				{
					key:3,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.hip.ca + tit.gas.ca + '<br>' + tit.teo.ca ,
						es: tit.pi.es + '<br>' + tit.hip.es + tit.gas.es + '<br>' + tit.teo.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
					
				},
				{
					key:4,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.hip.ca + tit.gas.ca + '<br>' + tit.teo.ca ,
						es: tit.ph.es + '<br>' + tit.hip.es + tit.gas.es + '<br>' + tit.teo.es,
					},
					uni: {ca: tit.uh.ca, es: tit.uh.es}
					
				},
				{
					key:5,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.hip.ca + tit.gas.ca + '<br>' + tit.teo.ca ,
						es: tit.pm.es + '<br>' + tit.hip.es + tit.gas.es + '<br>' + tit.teo.es,
					},
					uni: {ca: tit.um.ca, es: tit.um.es}
					
				},
				{
					key:6,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.ele.ca + '<br>' + tit.real.ca ,
						es: tit.pi.es + '<br>' + tit.ele.es+ '<br>'+  tit.real.es 
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
					
				},
				
				{
					key:7,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.gas.ca + '<br>' + tit.real.ca ,
						es: tit.pi.es + '<br>' + tit.gas.es + '<br>' + tit.real.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
					
				},
				{
					key:8,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.total.ca + '<br>' + tit.real.ca ,
						es: tit.pi.es + '<br>' + tit.total.es + '<br>' + tit.real.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
					
				},
				{
					key:9,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.total.ca + '<br>' + tit.real.ca ,
						es: tit.ph.es + '<br>' + tit.total.es + '<br>' + tit.real.es,
					},
					uni: {ca: tit.uh.ca, es: tit.uh.es}
					
				},
				{
					key:10,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.total.ca + '<br>' + tit.real.ca ,
						es: tit.pm.es + '<br>' + tit.total.es + '<br>' + tit.real.es,
					},
					uni: {ca: tit.um.ca, es: tit.um.es}
					
				},
				
				{
					key:11,
					subtitulo: {
						ca: tit.comp.ca ,
						es: tit.comp.es,
					},
					uni: {ca: '', es: ''}
					
				},
				
			],
		},
		{
			key:30,
			name:{
				ca:'Emissions del consum de calefacció',
				es:'Emisiones del consumo de calefacción'
			},
			unitat: {
				ca:'kgCO2',
				es:'kgCO2'
			},
			unitatTemps: {
				ca:'any',
				es:'año'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.teo.ca + '<br>' + tit.hip.ca + tit.ele.ca + '<br>' + tit.pi.ca,
						es: tit.teo.es + '<br>' + tit.hip.es + tit.ele.es + '<br>' + tit.pi.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
				},
				{
					key:1,
					subtitulo: {
						ca: tit.teo.ca + '<br>' + tit.hip.ca + tit.ele.ca + '<br>' + tit.ph.ca,
						es: tit.teo.es + '<br>' + tit.hip.es + tit.ele.es + '<br>' + tit.ph.es,
					},
					uni: {ca: tit.uh.ca, es: tit.uh.es}
					
				},
				{
					key:2,
					subtitulo: {
						ca: tit.teo.ca + '<br>' + tit.hip.ca + tit.ele.ca + '<br>' + tit.pm.ca,
						es: tit.teo.es + '<br>' + tit.hip.es + tit.ele.es + '<br>' + tit.pm.es,
					},
					uni: {ca: tit.um.ca, es: tit.um.es}
				},
				{
					key:3,
					subtitulo: {
						ca: tit.teo.ca + '<br>' + tit.hip.ca + tit.gas.ca + '<br>' + tit.pi.ca,
						es: tit.teo.es + '<br>' + tit.hip.es + tit.gas.es + '<br>' + tit.pi.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
				},
				{
					key:4,
					subtitulo: {
						ca: tit.teo.ca + '<br>' + tit.hip.ca + tit.gas.ca + '<br>' + tit.ph.ca,
						es: tit.teo.es + '<br>' + tit.hip.es + tit.gas.es + '<br>' + tit.ph.es,
					},
					uni: {ca: tit.uh.ca, es: tit.uh.es}
					
				},
				{
					key:5,
					subtitulo: {
						ca: tit.teo.ca + '<br>' + tit.hip.ca + tit.gas.ca + '<br>' + tit.pm.ca,
						es: tit.teo.es + '<br>' + tit.hip.es + tit.gas.es + '<br>' + tit.pm.es,
					},
					uni: {ca: tit.um.ca, es: tit.um.es}
				},
				{
					key:6,
					subtitulo: {
						ca: tit.real.ca + '<br>' + tit.ele.ca + '<br>' + tit.pi.ca,
						es: tit.real.es + '<br>' + tit.ele.es + '<br>' + tit.pi.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
				},
				
				{
					key:7,
					subtitulo: {
						ca: tit.real.ca + '<br>' + tit.gas.ca + '<br>' + tit.pi.ca,
						es: tit.real.es + '<br>' + tit.gas.es + '<br>' + tit.pi.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
				},
				{
					key:8,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.total.ca + '<br>' + tit.real.ca ,
						es: tit.pi.es + '<br>' + tit.total.es + '<br>' + tit.real.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
					
				},
				{
					key:9,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.total.ca + '<br>' + tit.real.ca ,
						es: tit.ph.es + '<br>' + tit.total.es + '<br>' + tit.real.es,
					},
					uni: {ca: tit.uh.ca, es: tit.uh.es}
					
				},
				{
					key:10,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.total.ca + '<br>' + tit.real.ca ,
						es: tit.pm.es + '<br>' + tit.total.es + '<br>' + tit.real.es,
					},
					uni: {ca: tit.um.ca, es: tit.um.es}
					
				},
				
				
			],
		},
		{
			key:31,
			name:{
				ca:'Embodied energy',
				es:'Embodied energy'
			},
			unitat: {
				ca:'kWh/hab',
				es:'kWh/viv'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca ,
						es: tit.ele.es 
					},
				},
				{
					key:1,
					subtitulo: {
						ca: tit.gas.ca ,
						es: tit.gas.es 
					},
					
				},
				
				
				
			],
		},
		{
			key:32,
			name:{
				ca:'Emissions de la intervenció',
				es:'Emissiones de la intervención'
			},
			unitat: {
				ca:'kgCO2/hab',
				es:'kgCO2/viv'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca ,
						es: tit.ele.es 
					},
				},
				{
					key:1,
					subtitulo: {
						ca: tit.gas.ca ,
						es: tit.gas.es 
					},
					
				},
				
				
				
			],
		},
		{
			key:33,
			name:{
				ca:'Qualificació energética: Consum de refrigeració',
				es:'Calificación energética: Consumo de refrigeración'
			},
			valores:[
				{
					ca:'A',
					es:'A'
				},
				{
					ca:'B',
					es:'B'
				},
				{
					ca:'C',
					es:'C'
				},
				{
					ca:'D',
					es:'D'
				},
				{
					ca:'E',
					es:'E'
				},
				{
					ca:'F',
					es:'F'
				},
				{
					ca:'G',
					es:'G'
				},
				{
					ca:'Sense dades / No residencial',
					es:'Sin datos / No residencial'
				},
			],
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.teo.ca ,
						es: tit.teo.es 
					},
				},
				{
					key:1,
					subtitulo: {
						ca: tit.real.ca ,
						es: tit.real.es 
					},
					
				},
				
				
				
			],
		},
		/*{
			key:34,
			name:{
				ca:'Consum real total',
				es:'Consumo real total'
			},
			unitat: {
				ca:'kWh/any',
				es:'kWh/año'
			},
			opciones: [
				{
					key:0,
					subtitulo:{
						ca: tit.pi.ca + '<br>' + tit.ele.ca,
						es: tit.pi.es + '<br>' + tit.ele.es,
					}
				},
				{
					key:1,
					subtitulo:{
						ca: tit.ph.ca + '<br>' + tit.ele.ca,
						es: tit.ph.es + '<br>' + tit.ele.es,
					}
				},
				{
					key:2,
					subtitulo:{
						ca: tit.pi.ca + '<br>' + tit.gas.ca,
						es: tit.pi.es + '<br>' + tit.gas.es,
					}
				},
				{
					key:3,
					subtitulo:{
						ca: tit.ph.ca + '<br>' + tit.gas.ca,
						es: tit.ph.es + '<br>' + tit.gas.es,
					}
				},
			]
		},*/
		{
			key:34,
			name:{
				ca:'Consum real total',
				es:'Consumo real total'
			},
			unitat: {
				ca:'kWh',
				es:'kWh'
			},
			unitatTemps: {
				ca:'any',
				es:'año'
			},
			opciones: [
				{
					key:0,
					subtitulo:{
						ca: tit.pi.ca + '<br>' + tit.ele.ca,
						es: tit.pi.es + '<br>' + tit.ele.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
				},
				{
					key:1,
					subtitulo:{
						ca: tit.pi.ca + '<br>' + tit.gas.ca,
						es: tit.pi.es + '<br>' + tit.gas.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
				},
				{
					key:2,
					subtitulo:{
						ca: tit.pi.ca + '<br>' + tit.total.ca,
						es: tit.pi.es + '<br>' + tit.total.es,
					},
					uni: {ca: tit.ui.ca, es: tit.ui.es}
				},
				{
					key:3,
					subtitulo:{
						ca: tit.ph.ca + '<br>' + tit.total.ca,
						es: tit.ph.es + '<br>' + tit.total.es,
					},
					uni: {ca: tit.uh.ca, es: tit.uh.es}
				},
				{
					key:4,
					subtitulo:{
						ca: tit.pm.ca + '<br>' + tit.total.ca,
						es: tit.pm.es + '<br>' + tit.total.es,
					},
					uni: {ca: tit.um.ca, es: tit.um.es}
				},
				
			]
		},

		{
			key:35,
			name:{
				ca:'Eficàcia en la reducció del consum real de calefacció',
				es:'Eficacia en la reducción del consumo real de calefacción'
			},
			unitat: {
				ca:'kWh/MWh·any',
				es:'kWh/MWh·año'
			},
			opciones: [
				{
					key:0,
					subtitulo:{
						ca: tit.hip.ca + tit.ele.ca + '<br>' + tit.teo.ca,
						es: tit.hip.es + tit.ele.es + '<br>' + tit.teo.es,
					}
				},
				{
					key:1,
					subtitulo:{
						ca: tit.hip.ca + tit.gas.ca + '<br>' + tit.teo.ca,
						es: tit.hip.es + tit.gas.es + '<br>' + tit.teo.es,
					}
				},
				{
					key:2,
					subtitulo:{
						ca: tit.ele.ca + '<br>' + tit.real.ca,
						es: tit.ele.es + '<br>' + tit.real.es,
					}
				},
				{
					key:3,
					subtitulo:{
						ca: tit.gas.ca + '<br>' + tit.real.ca,
						es: tit.gas.es + '<br>' + tit.real.es,
					}
				},
			]
		},
		{
			key:36,
			name:{
				ca:'Cost del consum de calefacció',
				es:'Coste del consumo de calefacción'
			},
			unitat: {
				ca:'€/any',
				es:'€/año'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.hip.ca + tit.ele.ca + '<br>' + tit.teo.ca ,
						es: tit.pi.es + '<br>' + tit.hip.es + tit.ele.es+ '<br>'+  tit.teo.es 
					},
					
				},
				{
					key:1,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.hip.ca + tit.ele.ca + '<br>' + tit.teo.ca ,
						es: tit.ph.es + '<br>' + tit.hip.es + tit.ele.es + '<br>' + tit.teo.es,
					},
					
				},
				{
					key:2,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.hip.ca + tit.ele.ca + '<br>' + tit.teo.ca ,
						es: tit.pm.es + '<br>' + tit.hip.es + tit.ele.es + '<br>' + tit.teo.es,
					},
					
				},
				{
					key:3,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.hip.ca + tit.gas.ca + '<br>' + tit.teo.ca ,
						es: tit.pi.es + '<br>' + tit.hip.es + tit.gas.es + '<br>' + tit.teo.es,
					},
					
				},
				{
					key:4,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.hip.ca + tit.gas.ca + '<br>' + tit.teo.ca ,
						es: tit.ph.es + '<br>' + tit.hip.es + tit.gas.es + '<br>' + tit.teo.es,
					},
					
				},
				{
					key:5,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.hip.ca + tit.gas.ca + '<br>' + tit.teo.ca ,
						es: tit.pm.es + '<br>' + tit.hip.es + tit.gas.es + '<br>' + tit.teo.es,
					},
					
				},
				{
					key:6,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.ele.ca + '<br>' + tit.real.ca ,
						es: tit.pi.es + '<br>' + tit.ele.es+ '<br>'+  tit.real.es 
					},
					
				},
				{
					key:7,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.ele.ca + '<br>' + tit.real.ca ,
						es: tit.ph.es + '<br>' + tit.ele.es + '<br>' + tit.real.es,
					},
					
				},
				{
					key:8,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.ele.ca + '<br>' + tit.real.ca ,
						es: tit.pm.es + '<br>' + tit.ele.es + '<br>' + tit.real.es,
					},
					
				},
				{
					key:9,
					subtitulo: {
						ca: tit.pi.ca + '<br>' + tit.gas.ca + '<br>' + tit.real.ca ,
						es: tit.pi.es + '<br>' + tit.gas.es + '<br>' + tit.real.es,
					},
					
				},
				{
					key:10,
					subtitulo: {
						ca: tit.ph.ca + '<br>' + tit.gas.ca + '<br>' + tit.real.ca ,
						es: tit.ph.es + '<br>' + tit.gas.es + '<br>' + tit.real.es,
					},
					
				},
				{
					key:11,
					subtitulo: {
						ca: tit.pm.ca + '<br>' + tit.gas.ca + '<br>' + tit.real.ca ,
						es: tit.pm.es + '<br>' + tit.gas.es + '<br>' + tit.real.es,
					},
					
				},
				
			],
		},
		{
			key:37,
			name:{
				ca:'Import del consum teòric per habitatge',
				es:'Importe del consumo teórico por vivienda'
			},
			unitat: {
				ca:'€/hab·any',
				es:'€/viv·año'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca,
						es: tit.ele.es
					},
				},
				{
					key:1,
					subtitulo: {
						ca: tit.gas.ca,
						es: tit.gas.es
					},
					
				},
				
				
				
			],
		},
		{
			key:38,
			name:{
				ca:'Risc de patir pobresa energètica',
				es:'Riesgo de sufrir pobreza energética'
			},
			unitat: {
				ca:'%',
				es:'%'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca ,
						es: tit.ele.es 
					},
				},
				{
					key:1,
					subtitulo: {
						ca: tit.gas.ca ,
						es: tit.gas.es 
					},
					
				},
				
				
				
			],
		},
		{
			key:39,
			name:{
				ca:'Inversió en la intervenció',
				es:'Inversión en la intervención'
			},
			unitat: {
				ca:'€/hab',
				es:'€/viv'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca ,
						es: tit.ele.es 
					},
				},
				{
					key:1,
					subtitulo: {
						ca: tit.gas.ca ,
						es: tit.gas.es 
					},
					
				},
				
				
				
			],
		},
		{
			key:40,
			name:{
				ca:'Creació de llocs de treball per la intervenció',
				es:'Creción de puestos de trabajo para la intervención'
			},
			unitat: {
				ca:'hores/immoble',
				es:'horas/inmueble'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca ,
						es: tit.ele.es 
					},
				},
				{
					key:1,
					subtitulo: {
						ca: tit.gas.ca ,
						es: tit.gas.es 
					},
					
				},
				
				
				
			],
		},
		{
			key:41,
			name:{
				ca:'Eficàcia econòmica en la reducció del consum de calefacció',
				es:'Eficacia económica en la reducción del consumo de calefacción'
			},
			unitat: {
				ca:'kWh/1000€·any',
				es:'kWh/1000€·año'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.hip.ca + tit.ele.ca + '<br>' + tit.teo.ca,
						es: tit.hip.es + tit.ele.es + '<br>' + tit.teo.es
					},
				},
				{
					key:1,
					subtitulo: {
						ca: tit.hip.ca + tit.gas.ca + '<br>' + tit.teo.ca,
						es: tit.hip.es + tit.gas.es + '<br>' + tit.teo.es
					},
					
				},
				{
					key:2,
					subtitulo: {
						ca: tit.ele.ca + '<br>' + tit.real.ca,
						es: tit.ele.es + '<br>' + tit.real.es
					},
				},
				{
					key:3,
					subtitulo: {
						ca: tit.gas.ca + '<br>' + tit.real.ca,
						es: tit.gas.es + '<br>' + tit.real.es
					},
				},
				
			],
		},
		{
			key:42,
			name:{
				ca:'Eficàcia económica en la reducció del consum teòric total',
				es:'Eficacia económica en la reducción del consumo teórico total'
			},
			unitat: {
				ca:'kWh/1000€·any',
				es:'kWh/1000€·año'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca,
						es: tit.ele.es,
					},
				},
				{
					key:1,
					subtitulo: {
						ca: tit.gas.ca,
						es: tit.gas.es,
					},
					
				},
				
			],
		},
		
		{
			key:43,
			name:{
				ca:'Temps d\'autonomía tèrmica',
				es:'Tiempo de autonomía térmica'
			},
			unitat:{
				ca:'%',
				es:'%'
			}

		},
		{
			key:44,
			name: {
				ca:'Salt tèrmic',
				es:'Salto térmico'
			},
			unitat:{
				ca:'graus·dia',
				es:'grados·día'
			}
		},
		{
			key:45,
			name:{
				ca:'Demanda de calefacció',
				es:'Demanda de calefacción'
			},
			opciones:[
				{
					key:0,
					subtitulo: {ca: tit.pi.ca , es: tit.pi.es },
					uni:{ca:tit.ui.ca, es:tit.ui.es}
					
				},
				{
					key:1,
					subtitulo: {ca: tit.ph.ca , es: tit.ph.es },
					uni:{ca:tit.uh.ca, es:tit.uh.es}
					
				},
				{
					key:2,
					subtitulo: {ca: tit.pm.ca , es: tit.pm.es },
					uni:{ca:tit.um.ca, es:tit.um.es}
					
				},
				
			],
			unitat: {
				ca: 'kWh',
				es: 'kWh'
			},
			unitatTemps: {
				ca: 'any',
				es: 'año'
			}
		},
		{
			key:46,
			name: {
				ca:'Renda mínima per evitar pobresa energètica',
				es:'Renta mínima para evitar la pobreza energética'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca + '<br>',
						es: tit.ele.es + '<br>'  
					},
					titulovar: {
						ca: tit.ele.ca + '<br>' + tit.var.ca,
						es: tit.ele.es + '<br>' + tit.var.es
					}
				},
				{
					key:1,
					subtitulo: {
						ca: tit.gas.ca + '<br>',
						es: tit.gas.es + '<br>'  
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.var.es
					}
				},
			/*	{
					key:2,
					subtitulo: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca,
						es: tit.gas.es + '<br>' + tit.cte.es 
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.cte.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.cte.es + '<br>' + tit.var.es
					}
				},
				{
					key:3,
					subtitulo: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca,
						es: tit.gas.es + '<br>' + tit.sal.es 
					},
					titulovar: {
						ca: tit.gas.ca + '<br>' + tit.sal.ca + '<br>' + tit.var.ca,
						es: tit.gas.es + '<br>' + tit.sal.es + '<br>' + tit.var.es
					}
				},*/
			],
			unitat: {
				ca:'€/hab·any',
				es:'€/viv·año'
			}
		},
		{
			key:47,
			name:{
				ca:'Qualificació energética: Demanda de calefacció',
				es:'Calificación energética: Demanda de calefacción'
			},
			valores:[
				{
					ca:'A',
					es:'A'
				},
				{
					ca:'B',
					es:'B'
				},
				{
					ca:'C',
					es:'C'
				},
				{
					ca:'D',
					es:'D'
				},
				{
					ca:'E',
					es:'E'
				},
				{
					ca:'F',
					es:'F'
				},
				{
					ca:'G',
					es:'G'
				},
				{
					ca:'Sense dades',
					es:'Sin datos'
				},
			],
			opciones: [
				{
					key:0,
					subtitulo:{
						ca: tit.ele.ca + '<br>' + tit.teo.ca,
						es: tit.ele.es + '<br>' + tit.teo.es,
					}
				},
				{
					key:1,
					subtitulo:{
						ca: tit.gas.ca + '<br>' + tit.teo.ca,
						es: tit.gas.es + '<br>' + tit.teo.es,
					}
				},
				
			]
		},
		{
			key:48,
			name:{
				ca:'Qualificació energètica: Consum de calefacció',
				es:'Calificación energética: Consumo de calefacción'
			},
			valores:[
				{
					ca:'A',
					es:'A'
				},
				{
					ca:'B',
					es:'B'
				},
				{
					ca:'C',
					es:'C'
				},
				{
					ca:'D',
					es:'D'
				},
				{
					ca:'E',
					es:'E'
				},
				{
					ca:'F',
					es:'F'
				},
				{
					ca:'G',
					es:'G'
				},
				{
					ca:'Sense dades',
					es:'Sin datos'
				},
			],
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.hip.ca + tit.ele.ca + '<br>' + tit.teo.ca,
						es: tit.hip.es + tit.ele.es + '<br>' + tit.teo.es
					}
				},
				{
					key:1,
					subtitulo: {
						ca: tit.hip.ca + tit.gas.ca + '<br>' +  tit.teo.ca,
						es: tit.hip.es + tit.gas.es + '<br>' +  tit.teo.es
					}
				},
				{
					key:2,
					subtitulo:{
						ca: tit.ele.ca + '<br>' + tit.real.ca,
						es: tit.ele.es + '<br>' + tit.real.es,
					}
				},
				{
					key:3,
					subtitulo:{
						ca: tit.gas.ca + '<br>' + tit.real.ca,
						es: tit.gas.es + '<br>' + tit.real.es,
					}
				},
			]
		},
		{
			key:49,
			name:{
				ca:'Eficàcia en la reducció del cost real de calefacció',
				es:'Eficacia en la reducción del coste real de calefacción'
			},
			unitat: {
				ca:'€/1000 € · any',
				es:'€/1000 € · año'
			},
			opciones: [
				{
					key:0,
					subtitulo: {
						ca: tit.ele.ca,
						es: tit.ele.es,
					},
				},
				{
					key:1,
					subtitulo: {
						ca: tit.gas.ca,
						es: tit.gas.es,
					},
					
				},
				
			],
		},
	],
	escenarios: [
		{key:0, ca:'Actual', es:'Actual'},
		{key:1, ca:'Canvi de fusteries', es:'Cambio de carpinterías'},
		{key:2, ca:'Aïllament de façanes', es:'Aislamiento de fachadas'},
		{key:3, ca:'Canvi de fusteries i aïllament de façanes', es:'Cambio de carpinterías y aislamiento de fachadas'},
		{key:4, ca:'Canvi de fusteries, aïllament de façanes i coberta', es:'Cambio de carpinterías, aislamiento de fachadas y cubierta'},
		{key:5, ca:'Canvi fusteries i aïllament de façanes', es:'Cambio de carpinterías y aislamiento de fachadas'},
		{key:6, ca:'Aïllament de façanes i coberta', es:'Aislamiento de medianeras y cubiertas'},
		{key:7, ca:'Canvi de fusteries i aïllament de façanes, cobertes, mitgeres i patis', es: 'Cambio de carpinterías y aislamiento de fachadas, cubiertas, medianeras y patios'},
		{key:8, ca:'Actual - Intervenció A', es:'Actual - Intervención A'},
		{key:9, ca:'Actual - Intervenció B', es:'Actual - Intervención B'},
		{key:10, ca:'Actual - Intervenció C', es:'Actual - Intervención C'},
		{key:11, ca:'Actual - Intervenció D', es:'Actual - Intervención D'},
		{key:12, ca:'Actual - Intervenció E', es:'Actual - Intervención E'},
	],
	unitats: [
		{key:0, ca:'Immoble', es:'Inmueble'},
		{key:1, ca:'Secció censal', es:'Sección censal'}
	],
	menuItems: [
		{key:0, ca:'Mapa', es:'Mapa'},
		{key:1, ca:'Estadístiques', es:'Estadísticas'},
		{key:2, ca:'Ajuda', es:'Ayuda'}
	],
	botonesleft: [
		
		{
			key:0,
			ca:'Escenari',
			es:'Escenario'

		},
		{
			key:1,
			ca:'Unitat de visualització',
			es:'Unidad de visualización'

		},
		{
			key:2,
			ca:'Indicadors',
			es:'Indicadores'

		},
		{
			key:3,
			ca:'Mapes',
			es:'Mapas'

		},
		{
			key:4,
			ca:'Filtres',
			es:'Filtros'

		},
		{
			key:5,
			ca:'Àmbit d\'estudi',
			es:'Ámbito de estudio'

		},
		{
			key:6,
			ca:'Llegenda',
			es:'Leyenda'

		},
	],
	botonesright: [
		{key:0, ca:'Compartir', es:'Compartir'},
		{key:1, ca:'Canviar el fons del mapa', es: 'Cambiar el fondo del mapa'},
		{key:2, ca:'Veure noms al mapa', es:'Ver nombres en el mapa'},
		{key:3, ca:'Mostrar el límit dels municipis', es:'Mostrar el límite de los municipios'}
	],
	botonesref: [
		{key:0, ca:'Referència', es:'Referencia'},
		{key:1, ca:'Inf.Arquitectònica', es: 'Inf.Arquitectónica'},
		{key:2, ca:'Inf.Energètica', es:'Inf.Energética'},
		{key:3, ca:'Inf.Econòmica', es: 'Inf.Económica'},
		{key:4, ca:'Opcions', es:'Opciones'}
	],
	modalShare:[
		{ca:'Comparteix el mapa actual:', es:'Comparte el mapa actual:'},
	],
	botonesbottom:{
		botones: [
			{
				ca:'Què es urbanZEB Open?', es:'¿Qué es urbanZEB Open?'
			},
			{
				ca:'Crèdits', es:'Créditos'
			},
			{
				ca:'Contacte', es: 'Contacto'
			},
			{
				ca:'CAT', es:'ES'
			},
			{
				ca:'Canviar idioma', es:'Cambiar idioma'
			}
		],
		que: [
			{
				ca:'urbanZEB Open és un servei gratuït emmarcat dins del projecte urbanZEB i focalitzat en la divulgació d\'indicadors urbans. La intenció es proporcionar informació per a l\'elaboració d\'estratègies urbanes de transformació de la ciutat cap a l\'equilibri ambiental i l\'equitat social, i, particularment, en el camp de la transició energètica. La informació disponible en aquesta pàgina web s\'ha generat mitjançant tècniques de GIS i Big Data a partir de dades de fonts públiques i privades. Entenem aquest espai com un lloc en creixement constant on mostrar indicadors que ens semblen interessants o útils per evaluar diferents aspectes urbanistics.',
				es:'urbanZEB Open es un servicio gratuito enmarcado dentro del proyecto urbanZEB y focalizado en la divulgación de indicadores urbanos. La intención es proporcionar información par la elaboración de estrategias urbanas de transformación de la ciudad hacia el equilibrio ambiental y la equidad social, y, particularmente, en el campo de la transición energética. La información disponible en esta página web se ha generado mediante técnicas de GIS y Big Data a partir de datos de fuentes interesantes o útiles para evaluar diferentes aspectos urbanísticos.'
			},
			{
				ca:'Tota la informació generada per Cíclica [space·comunity·ecology] continguda en aquesta pàgina web (open.urbanZEB.com) està publicada sota la llicència CC BY-NC-SA 4.0. La totalitat de les fonts utilitzades per a la generació d\'aquests indicadors pertanyen al Catastro de España, Ministerio de Hacienda. La informació dels fons dels mapes s\'ha generat a partir de la Base de Dades de OpenStreetMaps i per tant, en compliment amb la llicència ODbL, està a disposició de descàrrega lliure.',
				es: 'Toda la información generada por Cíclica [space·comunity·ecology] contenida en esta página web (open.urbanzeb.com) está publicada bajo la licencia CC BY-NC-SA 4.0. La totalidad de las fuentes utilizadas para la generación de estos indicadores perteneces al Catastro de España, Ministerio de Hacienda. La información de los fondos de mapas se ha generado a partir de la Basde de Datos de OpenStreetMaps y, por tanto, en cumplimiento con la licencia ODbL, están a disposición de descarga libre.'
			}
		],
		credits: [
			{
				ca:'urbanZEB és un projecte desenvolupat per',
				es:'urbanZEB es un proyecto desarrollado por'
			},
			{
				ca:'amb la col·laboració de',
				es:'con la colaboración de'
			},
			{
				ca:'amb el suport de',
				es:'con el apoyo de'
			},
		],
		contacto: {
			enviado: {
				ca:'Moltes gràcies per el teu missatge!',
				es:'¡Muchas gracias por tu mensaje!'
			},
			titulo: {
				ca:'Envia\'ns la teva consulta o suggeriment',
				es:'Envíanos tu consulta o sugerencia'
			},
			form:{
				nom: {
					ca:'Nom',
					es:'Nombre'
				},
				email:{
					ca:'Correu electrònic',
					es:'Correo electrónico'
				},
				assumpte: {
					ca:'Assumpte',
					es:'Asunto'
				},
				comment: {
					ca:'Missatge',
					es:'Mensaje'
				},
			},
			politica: [
				{ca:'Accepto la ', es:'Acepto la '},
				{ca:'Política de privacitat', es:'Política de privacidad'}
			],
			enviar: {
				ca:'Enviar', es:'Enviar'
			}
		}
	},
	crearSeleccio:{
		botones:[
			{ca:'Crear un nou àmbit d\'estudi amb els elements visibles ', es:'Crear un nuevo ámbito de estudio con los elementos visibles '},
			{ca:'Afegir els elements visibles a un àmbit d\'estudi existent ', es:'Añadir los elementos visibles a un ámbito de estudio existente '},
			{ca:'Eliminar els elements no visibles de l\'àmbit d\'estudi actiu ', es:'Eliminar los elementos no visibles del ámbito de estudio activo'},
		],
		crear: {
			tit: {ca:'Crear una selecció personalitzada', es:'Crear una selección persolanizada'},
			form: {
				alerta: [
					{ca:'Ja tens una selecció amb aquest nom', es:'Ya tienes una selección con este nombre'},
					{ca:'Has d\'introduïr un nom per a la teva selecció',es:'Tienes que introducir un nombre para tu selección'}
				],
				nom: {ca:'Nom',es:'Nombre'},
				descripcio: {ca:'Descripció',es:'Descripción'},
				desar: {ca:'Desar',es:'Guardar'}
			}
		},
		guardar:[
			{ca:'Tria a quina selección afegir els elements filtrats',es:'Escoge a qué selección añadir los elementos filtrados'},
			{ca:'Encara no has creat cap selecció personalitzada', es:'Todavía no has creado ninguna selección personalizada'}
		],
		eliminar:[
			{ca:'Estàs segur de que vols cambiar la configuració d\'aquest àmbit d\'estudi?',es:'¿Estás seguro de que quieres cambiar la configuración de este ámbito de estudio?'},
			{ca:'Cancel·lar ', es:'Cancelar '},
			{ca:'Continuar ', es:'Continuar '}
		],
		nuevo: {
			area: {
				ca:'Àrea personalitzada',
				es:'Área personalizada'
			},
			munis: {
				ca:'Municipi/s',
				es:'Municipio/s'
			},
			seccs: {
				ca:'Secció/ns Censal/s',
				es:'Sección/es Censal/es'
			}
		}
	},
	dataref: {
		ref:{
			ca:'Referència Cadastral:', es:'Referencia Catastral:'
		},
		adreca: {ca:'Adreça:', es:'Dirección:'},
		tits:{
			arq:{
				ca:'Informació Arquitectònica',
				es:'Información Arquitectónica'
			},
			ener:{
				ca:'Informació Energètica',
				es:'Información Energética'
			},
			eco:{
				ca:'Informació Econòmica',
				es:'Información Económica'
			},
			ind: {
				ca:'Indicador', es:'Indicador'
			},
			opt: {
				ca:'Opció', es:'Opción'
			},
			val: {
				ca:'Valor', es:'Valor'
			},
			actu: {
				ca:'Valor actual',
				es:'Valor actual'
			},
			post: {
				ca:'Valor post-intervenció',
				es:'Valor post-intervención'
			}
		}
	},
	escenari: {
		post: {
			ca:'Post-actuació',es:'Post-actuación'
		},
		comp: {
			ca:'Comparativa', es:'Comparativa'
		}
	},
	filtres:{
		guardar:{
			ca:'Desar elements filtrats ',es:'Guardar elementos filtrados '
		},
		aplicados:{
			ca:'Filtres aplicats',es:'Filtros aplicados'
		},
		ningun:{
			ca:'No has aplicat cap filtre', es:'No has aplicado ningún filtro'
		},
		area:{
			ca:'Àrea personalitzada', es:'Área personalizada'
		},
		secciones:{
			ca:'Seccions censals',es:'Secciones censales'
		},
		municipios:{
			ca:'Municipis', es:'Municipios'
		},
		nuevo: {
			ca:'Aplicar nou filtre', es:'Aplicar nuevo filtro'
		},
		geo:{
			ca:'Geogràfics', es:'Geográficos'
		},
		arq:{
			ca:'Arquitectònics', es:'Arquitectónicos'
		},
		ener: {
			ca:'Energètics',es:'Energéticos'
		},
		eco: {
			ca:'Econòmics', es:'Económicos'
		},
		gen: {
			ca:'Generals', es:'Generales'
		},
		singu: {
			ca:'Singulars', es:'Singulares'
		},
		seltodo: {
			ca:'Seleccionar / Deseleccionar tot',es:'Seleccionar / Deseleccionar todo'
		},
		max:{
			ca:'Màxim', es:'Máximo'
		},
		min: {
			ca:'Mínim', es:'Mínimo'
		},
		dibu: [
			{
				ca:'Selecciona l\'eina de dibuix per a filtrar un àreca personalitzada',
				es:'Selecciona la herramienta de dibujo para filtrar un área personalizada'
			},
			{
				ca:' Fes clic sobre les referències que vols seleccionar o deseleccionar',
				es:' Haz clic sobre las referencias que quieres seleccionar o deseleccionar'
			},
			{
				ca:' Fes clic en qualsevol punt del mapa per a començar a dibuixar un polígon. Pots tancar el polígon amb doble clic.',
				es:' Haz clic en cualquier punto del mapa para comenzar a dibujar un polígono. Puedes cerrar el polígono con doble clic.'
			},
			{
				ca:' Fes clic sobre el polígon que vols eliminar i després fes clic a la paperera.',
				es:' Haz clic sobre el polígono que quieres eliminar y después haz clic en la papelera.'
			},
			{
				ca:'El nivell de zoom ha de ser major pera a poder aplicar aquest filtre.',
				es:'El nivel de zoom tiene que ser mayor para poder aplicar este filtro.'
			}
		],
		selsecciones: {
			ca:'Fes clic sobre les seccions censals per afegir-les al teu filtre',
			es:'Haz clic sobre las secciones censales para añadirlas a tu filtro'
		},
		opciones: {
			ca:'Opcions filtre',
			es:'Opciones filtro'
		},
		cat: {
			ca:'Categoritzat',
			es:'Categorizado'
		},
		abs:{
			ca:'Absolut',
			es:'Absoluto'
		},
		val: {
			ca:'Valor',
			es:'Valor'
		},
		uni: {
			ca:'Unitat',
			es:'Unidad'
		},
		tot: {
			ca:'Total',
			es:'Total'
		},
		sobre:{
			ca:'Sobre rasant',
			es:'Sobre rasante'
		},
		sota:{
			ca:'Sota rasant',
			es:'Bajo rasante'
		},
		llin:{
			ca:'Llindar habitabilitat',
			es:'Umbral habitabilidad'
		},
		conf:{
			ca:'Confort',
			es:'Confort'
		},
		sal:{
			ca:'Salut',
			es:'Salud'
		},
		hip:{
			ca:'Hipòtesi vector energètic',
			es:'Hipótesis vector energético'
		},
		vec:{
			ca:'Vector energètic',
			es:'Vector energético'
		},
		ele: {
			ca:'Electricitat',
			es:'Electricidad'
		},
		gas: {
			ca:'Gas',
			es:'Gas'
		},
		pi: {
			ca:'Per immoble',
			es:'Por inmueble'
		},
		ph: {
			ca:'Per habitatge',
			es:'Por vivienda'
		},
		pm: {
			ca:'Per m<sup>2</sup>',
			es:'Por m<sup>2</sup>'
		},
		con: {
			ca:'Consum',
			es:'Consumo'
		},
		teor: {
			ca:'Teòric',
			es:'Teórico'
		},
		real: {
			ca:'Real',
			es:'Real'
		},
		nohay: {
			ca:'No hi ha informació d\'aquest indicador en les referencies que formen l\'àmbit seleccionat.',
			es:'No hay información de este indicador en las referencias que forman el ámbito seleccionado.'
		},
		comp: {
			ca:'Comparativa',
			es:'Comparativa'
		}
	},
	indis:{
		sel: {
			ca:'Indicador seleccionat',
			es:'Indicador seleccionado'
		},
		cambio:{
			ca:'Canviar indicador',
			es:'Cambiar indicador'
		},
		opciones: {
			ca:'Opcions indicador',
			es:'Opciones indicador'
		}

	},
	intro:{
		info1:{
			ca:'Benvingut a urbanZEB',
			es:'Bienvenido a urbanZEB'
		},
		info2:{
			ca:'Introdueix la teva adreça de correu electrònic per poder accedir al contingut.',
			es:'Introduce tu dirección de correo electrónico para poder acceder al contenido.'
		},
		info3: {
			ca: 'Aquesta aplicació es una mostra de les funcionalitats que té l\'aplicatiu urbanZEB. Tant les dades energètiques com les econòmiques que es mostren són una aproximació a aquells que s\'obtendrien en un estudi detallat.',
			es: 'Esta aplicación es una muestra de las funcionalidades que tiene la aplicación urbanZEB. Tanto los datos energéticos como los económicos que se muestran son una aproximación a los que se obtendrían en un estudio detallado.'
		},
		form:{
			nomail:{
				ca:'Has d\'introduïr una ádreça de correu electrònic',
				es:'Tienes que introducir una dirección de correo electrónico'
			},
			nopol:{
				ca:'Has d\'acceptar la política de privacitat',
				es:'Tienes que aceptar la política de privacidad'
			},
			accepto: {
				ca:'Accepto la',
				es:'Acepto la'
			},
			politica:{
				ca:'política de privacitat',
				es:'política de privacidad'
			},
			enviar: {
				ca:'Enviar',
				es:'Enviar'
			}
		}
	},
	barref: {
		opciones:{
			ca:'Opcions',
			es:'Opciones'
		},
		escpost:{
			ca:'Escenari Post-intervenció',
			es:'Escenario Post-intervención'
		}
	},
	leyenda: {
		omas:{
			ca:' o  més', es:' o  más',
		},
		omenos: {
			ca:' o menys', es:' o menos'
		},
		esp:[
			{ca:' 1 habitatge', es:' 1 vivienda'},
			{ca:' Planta Baixa', es:' Planta Baja'},
			{ca:' Sense costos', es:' Sin costes'}
		],
		fuentes:{
			ca:' Fonts: ', es:' Fuentes: '
		},
		errores:{
			ca:' La informació pot contenir errors', es:'La información puede contener errores'
		}

	},
	loading: {
		cargando:{
			ca:'Carregant', es:'Cargando'
		},
		calculando:{
			ca:'Estem recalculant les dades del teu àmbit personalitzat', es:'Estamos recalculando los datos de tu ámbito personalizado'
		},
		error: {
			ca:'S\'ha produït un error', es:'Se ha producido un error'
		}
	},
	login: {
		bienv:{
			ca:'Benvingut a urbanZEB', es:'Bienviendio a urbanZEB'
		},
		datos:{
			ca:'Introdueix les teves dades d\'usuari per poder accedir al contingut.', es:'Introduce tus datos de usuario para poder acceder al contenido.'
		},
		enviar: {
			ca:'Enviar', es:'Enviar'
		}
	},
	modalambit: {
		editar: {
			ca:'Edita el teu àmbit personalitzat',
			es:'Edita tu ámbito personalizado'
		},
		guardar: {
			ca:'Desar',
			es:'Guardar'
		},
		duplicar: {
			ca:'Introdueix un nou nom per a la copia del teu àmbit personalitzat ',
			es:'Introduce un nuevo nombre para la copia de tu ámbito personalizado '
		},
		seguro: {
			ca:'Estàs segur de que vols eliminar aquest àmbit personalitzat?',
			es:'¿Estás seguro de que quieres eliminar este ámbito personalizado?'
		},
		cancelar: {
			ca:'Cancel·lar', es:'Cancelar'
		},
		eliminar: {
			ca:'Eliminar', es:'Eliminar'
		},
		definicio:{
			ca:'Aquest àmbit d\'estudi està format per els següents grups d\'elements filtrats:',
			es:'Este ámbito de estudio está formado por los siguientes grupos de elementos filtrados:'
		},
		grupo: {
			ca:'Grup ', es:'Grupo '
		},
		min: {
			ca:'Mínim:', es:'Mínimo:'
		},
		max: {
			ca:'Màxim:', es:'Máximo'
		},
		numrefs: {
			ca:'Nombre de referències per escenari:',
			es:'Número de referencias por escenario: '
		},
		refs: {
			ca: 'referències', es:'referencias'
		},
		alerta1: {
			ca:'Ja tens una selecció amb aquest nom',
			es:'Ya tienes una selección con este nombre'
		},
		alerta2: {
			ca:'Has d\'introduïr un nom per a la teva selecció',
			es:'Tienes que introducir un nombre para tu selección'
		}
	},
	popup:{
		ref:{
			ca:'Referència Cadastral:',
			es:'Referencia Catastral:'
		},
		sec: {
			ca:'Secció Censal:',
			es:'Sección Censal:'
		},
		consultar: {
			ca:'Consultar',
			es:'Consultar'
		},
		sin: {
			ca:'Sense dades',
			es:'Sin datos'
		}
	},
	rightbar:{
		ambito:{
			ca:'Àmbit d\'estudi', es:'Ámbito de estudio'
		},
		general: {
			ca:'Àmbit general', es:'Ámbito general'
		},
		filtros: {
			ca:'FILTRES', es:'FILTROS'
		},
		pers: {
			ca:'Àrea personalitzada', es:'Área personalizada'
		},
		munis: {
			ca:'Municipis', es:'Municipios'
		},
		secs: {
			ca:'Seccions censals', es:'Secciones censales'
		}
	},
	tabla: {
		cargando:{
			ca:'Carregant', es:'Cargando'
		},
		ref:{
			ca:'Referència Cadastral', es:'Referencia Catastral'
		},
		dir: {
			ca:'Adreça', es:'Dirección'
		},
		consultar: {
			ca:'Consultar', es:'Consultar'
		},
		sin:{
			ca:'Sense dades', es:'Sin datos'
		}
	},
	vista:{
		gen:{
			ca:'Àmbit general', es:'Ámbito general'
		},
		pers: {
			ca:'Àmbits d\'estudi personalitzats', es:'Ámbitos de estudio personalizados'
		},
		nopers: {
			ca:'Encara no has creat cap àmbit personalitzat. Pots crear-lo des de l\'apartat Filtres.',
			es:'Todavía no has creado ningún ámbito personalizado. Puedes crearlo desde el apartado Filtros.'
		},
		desc: {
			ca:'Descripció',
			es:'Descripción'
		},
		numero: {
			ca:'Nombre de referències',
			es:'Número de referencias'
		}
	},
	noopt:{
		ca:'Opció no disponible en aquest indicador',
		es:'Opción no disponible en este indicador'
	}
}