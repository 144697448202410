<template>
	<div class="menu-escenaris">
		<label  v-for="unitat in unitats">
			<input type="radio" name="selunitat" :value="unitat.value" v-model="unitatVis" @change="updateUnitat(unitat.value)">
			<span>{{textos_lang.unitats[unitat.key][lang]}}</span>
			<div class="radio-input"></div>
		</label>
	</div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '../store/store'
export default {

  name: 'Unitat',
  computed:{
  	...Vuex.mapState(['unitats', 'unitatVis', 'textos_lang','lang', 'fondo']),
  	unitatVis: {
			get() {
				return store.state.unitatVis
			},
			set(value) {
				store.commit('change_unitat', value)
        store.commit('set_minmax')
			}
	  }
  },
  data () {
    return {

    }
  },
  methods: {
  	updateUnitat(value){
      var key;
      //si estoy en ámbito personalizado, guardo la key del ámbito. Si no, Key es 'general'
      if(store.state.vista){
        key = store.state.vista;
      } else {
        key = 'general'
      }
      //cogemos el objeto de la app en la que estamos
  		var app = store.state.aplicaciones.find(aplicacion => aplicacion.key == store.state.currentAppKey);
      //unitat immoble
  		if(value=='immoble'){
        //cambiamos capas  mapa y borde de los elementos
  			store.state.capaFondo['source-layer'] = 'refs_geom';
    		store.state.capaUzeb['source-layer'] = 'full';
        if(this.fondo=='Light') {
          store.state.capaUzeb['paint']['fill-outline-color'] = {
            "stops": [
                [
                  13.5,
                  "rgba(225, 225, 225, 0)"
                ],
                [
                  14,
                  "rgba(225, 225, 225, 1)"
                ]
            ]
          }
        }
        else if(this.fondo=='Dark') {
          store.state.capaUzeb['paint']['fill-outline-color'] = {
            "stops": [
                [
                  13.5,
                  "rgba(66,66,66, 0)"
                ],
                [
                  14,
                  "rgba(66,66,66, 1)"
                ]
            ]
          }
        }
        
  		} else if( value=='sec_censal') {
  			store.state.capaFondo['source-layer'] = 'sc_geom';
    		store.state.capaUzeb['source-layer'] = 'SC';
        if(this.fondo=='Light') {
          store.state.capaUzeb['paint']['fill-outline-color'] = "rgba(225, 225, 225, 1)"  
        }else if(this.fondo=='Dark') {
          store.state.capaUzeb['paint']['fill-outline-color'] = "rgba(66, 66, 66, 1)"  
        }
        
  		}
  		var map = store.state.mapelement;
      //eliminamos las capas y sus sources para volverlas a añadir
  		new Promise(resolve=> {
  			map.removeLayer('uzeb');
  			map.removeLayer('fondo');
  			map.removeSource('uzeb');
  			map.removeSource('fondo');
  			resolve()
  		}).then( function(){
  			map.addLayer(store.state.capaUzeb, store.state.textos);
  			map.addLayer(store.state.capaFondo,'uzeb');
        //volvemos a aplicar el estilo correspondiente
  			store.commit('change_estiloIndicador', map);
	    	map.setPaintProperty('uzeb','fill-color',store.state.estiloIndicador);
        store.dispatch('updateAmbit', {key})
        var buttons = document.querySelectorAll('.einaDibuix .mapbox-gl-draw_ctrl-draw-btn')
        if(buttons){
          if(store.state.currentZoom<13&&store.state.unitatVis=='immoble'){
            buttons.forEach(but => but.disabled = true)
          } else {
            buttons.forEach(but => but.disabled = false)
          }
        }
	    })
    	
  	}
  }
}
</script>

<style lang="css" scoped>
</style>