<template>
	<transition name="fadein">
			<div class="modal-mask" id="intro" v-if="intro">
      			<div class="modal-wrapper">
        			<div class="modal-container">
						<div class="emailform">
		 	
						 	<div class="formcont">
						 		<img src="../assets/urbanzeb.svg" alt="logo" class="brand" data-src="../assets/urbanzeb.svg" data-src-retina="../assets/urbanzeb.svg" width="350" >
						 		<p class="info">
                  {{textos_lang.intro.info1[lang]}} <span v-html="currentApp.titulo"></span>.
                  <br> {{textos_lang.intro.info3[lang]}}
                  <br> {{textos_lang.intro.info2[lang]}}
                </p>

								<div class="formulario">	
									<form id="contactform" method="POST">
									 	<div class="message-contactform"></div>
									 	<div v-if="nohayEmail" class="error">{{textos_lang.form.nomail[lang]}}</div>
									 	<div v-if="emailMal" class="error" v-html="errorMail"></div>
						            	<input  class="form-control" type="text" name="email" placeholder="E-mail" id="contact-email">
						            	<div v-if="nohayPolitica" class="error">{{textos_lang.form.nopol[lang]}}</div>
						            	<div class="form-check"> 
						            		
						            		<input class="form-check-input" type="checkbox" name="acepto" id="acepto" required> 
						            		
						            		<label><span>{{textos_lang.intro.form.accepto[lang]}} </span><a target="_blank" href="http://urbanzeb.com/politica.html"> {{textos_lang.intro.form.politica[lang]}}</a></label>
						            	</div>
						            
						            <div class="botonform">
						                <button type="submit" form="contactform" @click.prevent="sendEmail($event)"><span>{{textos_lang.intro.form.enviar[lang]}}</span> <div class="boton"><font-awesome-icon icon="paper-plane" /></div></button>
						            </div>
						            </form>
								</div>
							</div>
						 	<div class="logosini">
								<div class="colab">
								<div class="div1">
									<p>{{textos_lang.botonesbottom.credits[0][lang]}}</p>
									<div class="imagen"><a href="http://ciclica.eu" target="_blank"><img src="../assets/ciclica.png"></a></div>
								</div>
								<div class="div3">
									<p>{{textos_lang.botonesbottom.credits[1][lang]}}</p>
									<div class="imagen"><a href="https://etsav.upc.edu/ca" target="_blank"><img src="../assets/eav.png"></a></div>
								</div>
							</div>
								<div class="div5">
									<p>{{textos_lang.botonesbottom.credits[2][lang]}}</p>
									<div class="imagen">
										<a href="http://aracoop.coop/" target="_blank"><img src="../assets/aracoop.png"></a>
										<a href="http://treballiaferssocials.gencat.cat/ca/inici" target="_blank"><img src="../assets/gencat.png"></a>
										<a href="http://www.mitramiss.gob.es/" target="_blank"><img src="../assets/empleo.png"></a>
									</div>
								</div>
							</div>
							
							
						
						</div>
					</div>
				</div>
			</div>
	</transition>
</template>

<script>
import Vuex from 'vuex'
import axios from 'axios';
import mapMixins from '@/mixins/mapMixins'
import { mapMutations } from 'vuex'
export default {

  name: 'Intro',
  mixins:[mapMixins],
  data () {
    return {
    	nohayEmail: false,
    	nohayPolitica: false,
    	emailMal:false,
    	errorMail:'',
    	intro: true,
    }
  },
  created(){
  	if(document.cookie.indexOf('emailentered')>=0){
  		this.intro=false;
  	}
  },
  computed: {
  	...Vuex.mapGetters(['currentApp']),
  	...Vuex.mapState(['textos_lang','lang'])
  },
  methods:{
    ...mapMutations(['setHideIntro']),
  	sendEmail(event) {
  		var email = document.querySelector('input[name="email"]');
  		var politica = document.querySelector('input[name="acepto"]');
  		if(!email.value){
  			this.nohayEmail = true;
  		} else {
  			this.nohayEmail = false;
  		}
  		if(acepto.checked==false){
  			this.nohayPolitica = true;
  		} else {
  			this.nohayPolitica = false;
  		}
  		if(email.value&&acepto.checked==true){
  			 axios({
                  method: 'POST',
                  url: `api/storemail/`,
                  data: {'email': email.value}
                })
                .then(response => {
                	if(response.data.Succes) {
                    this.setHideIntro(true)
                		this.intro = false;
                		var d = new Date();
						        d.setTime(d.getTime() + (3*24*60*60*1000));
						        var expires = "expires="+ d.toUTCString();
						        document.cookie = "emailentered=yes;" + expires + ";path=/";
                    if(this.currentApp.key==8) {
                      var _this = this;
                      setTimeout(function(){
                        const bounds = [[3.8654147, 40.0114616],[3.80851147,39.988094]]
                        _this.fitCityBounds(bounds)
                      },2000)
                    }
						
                	}
                	if(response.data.Invalid){
                		this.errorMail=response.data.Invalid;
                		this.emailMal=true;
                	}
                })
             
                /*.then(response => this.callback(response.data))*/
                .catch(response => console.log('submit error: '+ response))

			/*event.submmit()*/

		
  		}

  		
  	}
  }
}
</script>

<style lang="css">
</style>